import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {}

const formInit = (state, action) => {
  if (action.cat in state && action.form in state[action.cat]) {
    const updState = { ...state }
    updState[action.cat][action.form] = { ...updState[action.cat][action.form], ...action.newEl }
    return updState
  } else if (state[action.cat] && !(action.form in state[action.cat])) {
    const updState = { ...state }
    updState[action.cat][action.form] = action.newEl
    return updateObject(state, updState)
  } else {
    let updObj = {}
    updObj[action.cat] = {}
    updObj[action.cat][action.form] = action.newEl
    return updateObject(state, updObj)
  }

}

const formSetOption = (state, action) => {
  let updState = { ...state }
  let updData = { ...state[action.cat][action.formId][action.elId], ...action.value }
  updState[action.cat][action.formId][action.elId] = updData
  return updateObject(state, updState)
}

const formDelete = (state, action) => {
  if (Object.keys(state[action.cat][action.formId]).length > 1) {
    let updState = { ...state }
    delete updState[action.cat][action.formId][action.elId]
    return updState
  }
  else
    return state
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.FORMS_INIT: return formInit(state, action)
    case actypes.FORMS_SET_OPTION: return formSetOption(state, action)
    case actypes.FORMS_DELETE: return formDelete(state, action)
    default: return state
  }

}

export default reducer