import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Home from '../../layout/Home/Home';
import Spinner from '../../layout/Spinner/Spinner';
import * as actions from '../../store/actions';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Checkout from '../Checkout/Checkout';
import Admin from './Admin';

const BusinessApp = lazy(() => import('./BusinessApp'));
const DonorApp = lazy(() => import('./DonorApp'));
const Thanks = lazy(() => import('./Thanks'));
const UserAccount = lazy(() => import('../User/Account/Account'));
const UserBadges = lazy(() => import('../User/Badges/Badges'));
const UserBadge = lazy(() => import('../User/Badge/Badge'));
const UserPassword = lazy(() => import('../User/Password/Password'));
const SingleBadge = lazy(() => import('../SingleBadge/SingleBadge'));
const Projects = lazy(() => import('../Home/Projects'));
const Project = lazy(() => import('../Home/Project'));
const Widget = lazy(() => import('../Home/Widget'));

const MainContainer = (props) => {
  const app = useSelector((state) => state.pages.app);
  const current = useSelector((state) => state.pages.current);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (current && current.id && !['instructions', 'badges', 'checkout'].includes(current.id)) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [current]);

  const handleBeforeUnload = (e) => {
    // if (process.env.REACT_APP_BUILD_ENV) {
    e.preventDefault();
    const message = 'Are you sure you want to leave? All provided data will be lost.';
    e.returnValue = message;
    return message;
    // }
  };

  useEffect(() => {
    dispatch(actions.authCheckState());
  }, [dispatch]);

  const ActiveComponent = () => {
    if (!app) {
      return <Home />;
    } else {
      if (app === 'bc' && token) return <BusinessApp />;
      if (app === 'dp' && token) return <DonorApp />;
      if (app === 'thanks' && token) return <Thanks />;

      return <Home />;
    }
  };

  let routes = (
    <Switch>
      <Route path="/checkout" component={Checkout} />
      <Route path="/payment" component={Thanks} />
      <Route path="/user/account" component={UserAccount} />
      <Route path="/user/badges/:id" component={UserBadge} />
      <Route path="/user/badges" exact component={UserBadges} />
      <Route path="/user/password/:token" exact component={UserPassword} />
      <Route path="/admin" component={Admin} />
      <Route path="/badge/:id" component={SingleBadge} />
      <Route path="/projects/:id" component={Project} />
      <Route path="/projects" component={Projects} />
      <Route path="/widget" component={Widget} />
      <Route path="/" exact component={ActiveComponent} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <BrowserRouter>
      <Fragment>
        <Suspense fallback={<Spinner />}>
          {routes}
          {/* <ActiveComponent /> */}
        </Suspense>
      </Fragment>
    </BrowserRouter>
  );
};

export default MainContainer;
