import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {}

const setOptions = (state, action) => {
  return updateObject(state, action.payload)
}


const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.SET_OPTIONS: return setOptions(state, action)
    default: return state
  }
}

export default reducer