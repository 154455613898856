import axios from 'axios';

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_BUILD_ENV === 'staging'
      ? 'https://business-backend.standfortrees.life/api/v1/'
      : process.env.REACT_APP_BUILD_ENV === 'production'
      ? 'https://business-backend.standfortrees.org/api/v1/'
      : 'http://localhost:3100/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

export const emailError = async (body) => {
  return instance.post('/utils/errors', { to: process.env.SUPPORT, body });
};

export default instance;
