
import * as actypes from '../actypes'
import { jumpPage } from './pages'

export const thanksReset = () => {
  return {
    type: actypes.THANKS_RESET
  }
}

export const thanksSet = (payload) => {
  return dispatch => {
    dispatch(_thanksSet(payload))
    dispatch(jumpPage('thanks'))
  }
}

export const _thanksSet = (payload) => {
  return {
    type: actypes.THANKS_SET,
    payload
  }
}