import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {
  employees: '',
  revenue: '',
  price: 0,
  currency: 'USD',
  volDiscount: true,
}

const donorSet = (state, action) => {
  return updateObject(state, action.payload)
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.DONOR_SET: return donorSet(state, action)
    default: return state
  }
}

export default reducer