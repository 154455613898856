import * as actypes from '../actypes';
import axios from '../../api/axios';
import { authLogout } from './auth';
import { projectsReset } from './projects';

export const badgesReset = () => {
  return {
    type: actypes.BADGES_RESET,
  };
};

export const badgesInit = () => {
  return (dispatch, getState) => {
    if (localStorage.getItem('token')) {
      const app = getState().pages.app;
      const byLevel = getState().results.byLevel;

      let level = 0;

      for (const [key, val] of Object.entries(byLevel)) {
        if (val > 0) level = key;
      }

      axios
        .get(`/badges?app=${app}&level[lte]=${level}&sort=order`)
        .then((res) => {
          dispatch(badgesSet(res.data.data, byLevel));
        })
        .catch((err) => {
          console.error(err);
        });
    } else dispatch(authLogout());
  };
};

export const badgesSet = (payload) => {
  return {
    type: actypes.BADGES_SET,
    payload,
  };
};

// export const badgesUpdate = (id, payload) => {
//   return {
//     type: actypes.BADGES_UPDATE,
//     id,
//     payload
//   }
// }

export const badgesSelected = (payload) => {
  return (dispatch, getState) => {
    dispatch(projectsReset());
    dispatch(_badgesSelected(payload));
  };
};

export const _badgesSelected = (payload) => {
  return {
    type: actypes.BADGES_SELECTED,
    payload,
  };
};

export const badgesSave = (payload) => {
  return (dispatch, getState) => {
    if (localStorage.getItem('token')) {
      axios
        .post('/orders', payload)
        .then((res) => {
          window.location.replace(`/payment?success=true`);
        })
        .catch((err) => {
          console.error(err);
        });
    } else dispatch(authLogout());
  };
};
