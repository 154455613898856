import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

import signup from './Signup.js';
import signin from './Signin.js';
import details from './Details';
import guidance from './Guidance';
import password from './Password';

const MODALS = { signup, signin, details, guidance, password };

const Modal = (props) => {
  if (!props.modalType) return null;

  const style = props.modalData.forceOpen
    ? {
        backgroundImage: `linear-gradient(to bottom, rgba(0,78, 89,0.7), rgba(0,78, 89,0.7)),
    url('${require('../../assets/img/jungle.jpg')}')`,
      }
    : null;

  const DisplayModal = MODALS[props.modalType];
  return (
    <div
      className="bg-black bg-opacity-25 fixed w-full h-full left-0 top-0 overflow-y-auto"
      style={style}
    >
      {props.modalData.forceOpen && (
        <div className="mx-auto text-center">
          <img
            className="block mx-auto h-20"
            alt="Stand For Trees Logo"
            src={require('../../assets/img/logo-white.png')}
          />
          <h1 className="text-lg text-white mb-2">Business Programs</h1>
        </div>
      )}
      <div
        className={`bg-white p-12 ${
          !props.modalData.forceOpen && 'my-8 md:mt-32'
        } w-screen md:w-2/3 lg:w-3/4 max-w-4xl  mx-auto text-center rounded relative`}
      >
        {!props.modalData || !props.modalData.forceOpen ? (
          <button
            className="block font-light text-gray-500 text-2xl absolute top-0 right-0 mr-5 mt-5"
            onClick={props.onClose}
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        ) : null}
        <DisplayModal {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state.modal;
const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.modalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
