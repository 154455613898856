import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from '../../../api/axios'
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner'

const DiscountAdd = () => {

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updated, setUpdated] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const submitHandler = async (data) => {
    setLoading(true)

    data.codeGeneration = 'manual'

    axios.post(`/admin/discounts/`, data)
      .then((res) => {
        setUpdated(true)
        setError(false)
      })
      .catch(() => {
        setUpdated(false)
        setError(true)
      })

    setLoading(false)
  }

  return (
    <div className="flex flex-wrap lg:flex-no-wrap mx-auto shadow-md rounded py-4 px-8 lg:space-x-8 mb-6">
      <form className="w-full" onSubmit={handleSubmit(submitHandler)}>

        <h5 className="text-teal-500">Code</h5>
        <input type="text" className="my-2 w-full" name="code" ref={register({ required: true })} />
        {errors.code && <p className="error">Please enter code</p>}

        <h5 className="text-teal-500">Type</h5>
        <div className="relative w-full styled-select my-2">

          <select className="focus:outline-none focus:bg-white focus:border-gray-500"
            name="discount"
            ref={register()}>
            <option value="fixed">Fixed Amount</option>
            <option value="percentage">Percentage Based Amount</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
          </div>
        </div>

        <h5 className="text-teal-500">Value</h5>
        <input type="number" className="my-2 w-full" name="value" ref={register({ required: true })} />
        {errors.value && <p className="error">Please enter value</p>}

        <h5 className="text-teal-500">How many discounts with this code</h5>
        <input type="number" className="my-2 w-full" name="size" ref={register({ required: true })} />
        {errors.size && <p className="error">Please enter size</p>}

        <h5 className="text-teal-500">Start Date</h5>
        <input type="date" className="my-2 w-full" name="startsAt" ref={register({ required: true })} />
        {errors.startsAt && <p className="error">Please enter Start Date</p>}

        <h5 className="text-teal-500">Expiry Date</h5>
        <input type="date" className="my-2 w-full" name="expiresAt" ref={register({ required: true })} />
        {errors.expiresAt && <p className="error">Please enter Expiry Date</p>}

        <button className="btn block mt-8 mx-auto" type="submit">{loading ? <BtnSpinner /> : 'Save Details'}</button>
        {updated && <p className="admin-info">Discount updated successfully</p>}
        {error && <p className="admin-error">Error updating discount</p>}
      </form>

    </div>

  )
}

export default DiscountAdd