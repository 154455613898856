import React, { Fragment, useRef } from 'react';
import { useForm } from 'react-hook-form';

const Password = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();

  const onPassword = (payload) => props.modalData.resetPassword(payload);

  const password = useRef({});
  password.current = watch('password', '');

  return (
    <Fragment>
      <h1 className="mb-8 text-gray-700">Password</h1>
      <form onSubmit={handleSubmit(onPassword)}>
        <input
          className="mt-4 mx-auto w-full"
          type="password"
          name="password"
          ref={register({
            required: true,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
          })}
          placeholder="New Password"
        />
        {errors.password && (
          <p className="error">Minimum 8 characters. One uppercase, one special, one number</p>
        )}

        <input
          className="mt-4 mx-auto w-full"
          type="password"
          name="passwordConfirm"
          ref={register({ required: true, validate: (value) => value === password.current })}
          placeholder="Confirm Password"
        />
        {errors.passwordConfirm && <p className="error">Passwords do not match</p>}
        {!props.modalData.message && (
          <button className="btn block mt-8 mx-auto" type="submit">
            Reset Password
          </button>
        )}
      </form>
      <p className="mt-4 text-gray-500 text-center">{props.modalData.message}</p>
      {props.modalData.message && <a href="/">Go to Homepage</a>}
    </Fragment>
  );
};

export default Password;
