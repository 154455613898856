import React, { Fragment, useState } from 'react'
import {
  Link,
  Route,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from '../../api/axios'
import { isEmpty } from 'lodash'


import * as actions from '../../store/actions'

import App from '../../layout/App/App'
import Spinner from '../../layout/Spinner/Spinner'
import Modal from '../Modal/Modal'
import FetchError from '../Shared/FetchError'

import AdminComponent from '../../components/Admin/Admin'
import Orders from '../../components/Admin/Orders/Orders'
import Order from '../../components/Admin/Orders/Order/Order'
import Users from '../../components/Admin/Users/Users'
import User from '../../components/Admin/Users/User'
import UserAdd from '../../components/Admin/Users/UserAdd'
import Projects from '../../components/Admin/Projects/Projects'
import AdminProject from '../../components/Admin/Projects/Project'
import ProjectAdd from '../../components/Admin/Projects/ProjectAdd'
import Extras from '../../components/Admin/Extras/Extras'
import Extra from '../../components/Admin/Extras/Extra'
import ExtraAdd from '../../components/Admin/Extras/ExtraAdd'
import Discounts from '../../components/Admin/Discounts/Discounts'
import DiscountAdd from '../../components/Admin/Discounts/DiscountAdd'
import Discount from '../../components/Admin/Discounts/Discount'
import ManualBadge from '../../components/Admin/ManualBadge/ManualBadge'


const Admin = (props) => {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  const [account, setAccount] = useState({})
  const [error, setError] = useState(false)
  let activeElement = <Spinner />


  if (!auth.token) {
    activeElement = <div><Modal /></div>
    dispatch(actions.modalOpen({ modalType: 'signin', modalData: { forceOpen: true } }))
  }

  if (auth.token && isEmpty(account)) {
    axios.get(`/admin/account`)
      .then(response => {
        if (response.data.data.length === 0)
          throw new Error('No user found')

        setAccount(response.data.data)
      })
      .catch(err => {
        if ((err.response && err.response.status === 401) || (err.response && err.response.status === 403))
          dispatch(actions.authLogout())
        else
          setError(true)
      })
  }


  if (error)
    activeElement = <App><FetchError error="There was an error fetching your account." /></App>


  if (auth.token && !error && !isEmpty(account)) {
    dispatch(actions.modalClose({ modalType: 'signin' }))
    activeElement = (
      <App nocontainer nopadding>
        <div className="flex" style={{ minHeight: '89vh' }}>
          <div className="w-1/12 dashboard-sidebar flex-grow">
            <ul>
              <li><Link to={`${props.match.url}/orders`}>Orders</Link></li>
              <li><Link to={`${props.match.url}/users`}>Users</Link></li>
              <li><Link to={`${props.match.url}/projects`}>Projects</Link></li>
              <li><Link to={`${props.match.url}/extras`}>Extras</Link></li>
              <li><Link to={`${props.match.url}/discounts`}>Discounts</Link></li>
              <li><Link to={`${props.match.url}/manual`}>Manual Badge</Link></li>
            </ul>
          </div>
          <div className="w-11/12 flex-grow py-10 px-5">
            <Route path="/" component={AdminComponent} />
            <Route path={`${props.match.url}/orders`} exact component={Orders} />
            <Route path={`${props.match.url}/orders/:id`} exact component={Order} />
            <Route path={`${props.match.url}/users`} exact component={Users} />
            <Route path={`${props.match.url}/add/users`} exact component={UserAdd} />
            <Route path={`${props.match.url}/users/:id`} exact component={User} />
            <Route path={`${props.match.url}/projects`} exact component={Projects} />
            <Route path={`${props.match.url}/add/projects/`} exact component={ProjectAdd} />
            <Route path={`${props.match.url}/projects/:id`} exact component={AdminProject} />
            <Route path={`${props.match.url}/add/extras`} exact component={ExtraAdd} />
            <Route path={`${props.match.url}/extras`} exact component={Extras} />
            <Route path={`${props.match.url}/extras/:id`} exact component={Extra} />
            <Route path={`${props.match.url}/add/discounts/`} exact component={DiscountAdd} />
            <Route path={`${props.match.url}/discounts`} exact component={Discounts} />
            <Route path={`${props.match.url}/discounts/:id`} exact component={Discount} />
            <Route path={`${props.match.url}/manual`} exact component={ManualBadge} />
          </div>
        </div>
      </App>
    )
  }

  return (
    <Fragment>
      {activeElement}
    </Fragment>
  )
}
export default Admin