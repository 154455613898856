import * as actypes from '../actypes'
// import { updateObject } from '../helpers'

const initState = {
  name: null,
  email: null,
  token: null,
  error: false,
}

const authSuccess = (state, action) => {
  return {
    ...state,
    email: action.email,
    token: action.token,
    name: action.name
  }
}

const authFail = (state, action) => {
  return {
    ...state,
    error: action.error
  }
}

const authLogout = (state, action) => {
  return initState
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.AUTH_SUCCESS: return authSuccess(state, action)
    case actypes.AUTH_FAIL: return authFail(state, action)
    case actypes.AUTH_LOGOUT: return authLogout(state, action)
    default: return state
  }
}

export default reducer