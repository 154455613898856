import React, { Fragment } from 'react'
import parse from 'html-react-parser'
import guidance from '../../constants/guidance'

const Guidance = props => {

  return (
    <Fragment>
      <h1 className="mb-6">Guidance Note</h1>
      <div className="content text-left text-lg">
        {parse(guidance[props.modalData.form])}
      </div>
    </Fragment>
  )
}

export default Guidance