import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from '../../../api/axios';

import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';

const ProjectAdd = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const createProjectHandler = async (data) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('image', data.image[0]);

    if (data.cert) formData.append('cert', data.cert[0]);

    if (data.moreInfo) formData.append('moreInfo', data.cert[0]);

    for (const [field, value] of Object.entries(data)) {
      if (field !== 'image' && field !== 'cert' && field !== 'moreInfo')
        formData.append(field, value);
    }

    formData.append('path', `projects/${data.prj_code}`);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios
      .post('/admin/projects', formData, config)
      .then(() => setUpdated(true))
      .catch(() => setError(true));

    setLoading(false);
  };

  return (
    <Fragment>
      <h1 className="mb-6 text-gray-700 text-center">Create Project</h1>
      <div className="card-lg">
        <h3 className="mb-6 text-gray-700">Project Details</h3>
        <form onSubmit={handleSubmit(createProjectHandler)}>
          <h5 className="text-teal-500">Name</h5>
          <input
            type="text"
            className="my-2 w-full"
            name="name"
            ref={register({ required: true })}
          />
          {errors.name && <p className="error">Please enter name</p>}

          <h5 className="text-teal-500">Details</h5>
          <input type="text" className="my-2 w-full" name="details" ref={register()} />
          {errors.details && <p className="error">Please enter details</p>}

          <h5 className="text-teal-500">Country</h5>
          <input
            type="text"
            className="my-2 w-full"
            name="country"
            ref={register({ required: true })}
          />
          {errors.country && <p className="error">Please enter country</p>}

          <h5 className="text-teal-500">Project Code</h5>
          <input
            type="text"
            className="my-2 w-full"
            name="prj_code"
            ref={register({ required: true })}
          />
          {errors.prj_code && <p className="error">Please enter prj_code</p>}

          <h5 className="text-teal-500">Status</h5>
          <div className="relative w-full styled-select my-2">
            <select
              className="focus:outline-none focus:bg-white focus:border-gray-500"
              name="status"
              ref={register({ required: true })}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          <h5 className="text-teal-500">Project Image</h5>
          <input
            type="file"
            accept="image/png, image/jpeg"
            className="my-2 w-full"
            name="image"
            ref={register({ required: true })}
          />
          {errors.image && <p className="error">Please enter image</p>}

          <h5 className="text-teal-500">Project Certificate</h5>
          <input
            type="file"
            accept="image/png, image/jpeg"
            className="my-2 w-full"
            name="cert"
            ref={register()}
          />
          {errors.cert && <p className="error">Please enter certificate</p>}

          <h5 className="text-teal-500">Project More Info PDF</h5>
          <input
            type="file"
            accept="application/pdf"
            className="my-2 w-full"
            name="moreInfo"
            ref={register()}
          />
          {errors.cert && <p className="error">Please enter moreInfo PDF File</p>}

          <button className="btn block mt-8 mx-auto" type="submit">
            {loading ? <BtnSpinner /> : 'Create Project'}
          </button>
          {updated && <p className="admin-info">Project created successfully</p>}
          {error && <p className="admin-error">Error creating project</p>}
        </form>
      </div>
    </Fragment>
  );
};

export default ProjectAdd;
