import axios from '../api/axios'

const downloadPdfHandler = async (endpoint, id, filename) => {

  return await axios.get(`${endpoint}/${id}`, {
    method: 'GET',
    responseType: 'blob', // important
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}-${Date.now()}.pdf`);
      document.body.appendChild(link);
      link.click();
      return true
    })
    .catch(err => {
      console.error(err)
      return false
    })

}

export default downloadPdfHandler