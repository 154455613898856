import React, { useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { getDataApi } from '../../../utils/api';
import DiscountInfo from './DiscountInfo';

const Discount = (props) => {
  const [dataSet, setDataSet] = useState({});

  const getSetData = async (query) => {
    const discount = await getDataApi(`${props.match.url}`, query);

    if (discount.startsAt) discount.startsAt = discount.startsAt.substring(0, 10);

    if (discount.expiresAt) discount.expiresAt = discount.expiresAt.substring(0, 10);

    setDataSet({ discount });
  };

  const updateDataSet = (field, data) => {
    const updData = { ...dataSet };
    updData[field] = { ...dataSet[field], ...data };
    setDataSet(updData);
  };

  if (isEmpty(dataSet)) getSetData();

  return (
    <div>
      <h3 className="text-gray-700 text-center text-3xl my-2">Discount Details</h3>
      {!isEmpty(dataSet) && (
        <Fragment>
          <DiscountInfo dataSet={dataSet} updateDataSet={updateDataSet} />
        </Fragment>
      )}
    </div>
  );
};
export default Discount;
