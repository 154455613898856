import React, { Fragment, useState } from 'react';
import { isEmpty } from 'lodash';
import Filters from './Filters';
import DateRange from './DateRange';

const Table = ({ headers, dataSet, getSet, setData, history, route, dateRangeCategories }) => {
  const [sortBy, setSortBy] = useState();
  const [filterBy, setFilterBy] = useState();
  const [filterValue, setFilterValue] = useState();
  const [asc, setAsc] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const filters = {};

  // create filters map
  if (!isEmpty(dataSet)) {
    dataSet.forEach((arr) => {
      for (const [key, value] of Object.entries(arr)) {
        //generate filters map
        if (!(key in filters)) {
          // populate filters object
          filters[key] = [];
        }

        // if value doesn't exist, push to relative category
        if (filters[key].indexOf(value) < 0) filters[key].push(value);
      }
    });
  }

  // sorting function for data set
  const setSort = (value) => {
    let sortedSet = [...dataSet];

    if (asc) sortedSet.sort((a, b) => (a[value] > b[value] ? 1 : b[value] > a[value] ? -1 : 0));
    else sortedSet.sort((a, b) => (a[value] < b[value] ? 1 : b[value] < a[value] ? -1 : 0));

    // getSet(apiUtils(value, filterBy, filterValue, asc))
    setData(sortedSet);
    dataSet = sortedSet;
    if (sortBy !== value) setSortBy(value);
    setAsc(!asc);
  };

  // filtering function for dataset
  const setFilter = (key, value) => {
    let filteredSet = [...dataSet];

    // assume key is already set
    if (value) {
      // eslint-disable-next-line
      filteredSet = dataSet.filter((d) => d[filterBy] == value);
      setData(filteredSet);
    }

    // repopulate dataSet from API if category or subcategory set to null, or if category if changed
    if ((filterBy && !key) || (filterBy && filterBy !== key) || (filterBy && !value)) {
      getSet();
    }

    // save filters to state
    if (filterBy !== key) setFilterBy(key);
    setFilterValue(value);
  };

  // filtering function for dataset
  const setFilterByDateRange = (key, value) => {
    let filteredSet = [...dataSet];

    // assume key is already set
    if (dateRange && dateRange.type && dateRange.start && dateRange.end) {
      // eslint-disable-next-line
      filteredSet = dataSet.filter((d) => {
        return d[dateRange.type] > dateRange.start && d[dateRange.type] < dateRange.end;
      });
      setData(filteredSet);
    }

    // repopulate dataSet from API if category or subcategory set to null, or if category if changed
    if (dateRange.type === 'none') getSet();
  };

  // generate table heads dynamically
  const th = headers.map((h) => (
    <th
      key={`header_${h}`}
      className="p-2 bg-teal-500 text-white text-left cursor-pointer"
      onClick={() => setSort(h)}
    >
      <span className="flex items-center">
        <span
          className={sortBy === h && asc ? 'arrow-up' : sortBy === h && !asc ? 'arrow-down' : null}
          style={{ minHeight: '5px' }}
        ></span>
        {h}
      </span>
    </th>
  ));
  // add options static th
  th.push(
    <th key={`header_${Math.random()}`} className="p-2 bg-teal-500 text-white text-left">
      Options
    </th>,
  );

  // generate table rows dynamically
  const fields = dataSet.map((d) => {
    const td = headers.map((h) => (
      <td key={h} className="border-b border-teal-200 p-2">
        {d[h]}
      </td>
    ));

    // push static details column per row
    td.push(
      <td key={`options_${Math.random()}`} className="border-b border-teal-200 p-2">
        <button
          className="btn"
          // onClick={() => history.push(d._id)}
          onClick={() => history.push({ pathname: `${route}/${d._id}`, state: d })}
        >
          Details
        </button>
      </td>,
    );

    return (
      <tr key={Math.random()} className="bg-white">
        {[...td]}
      </tr>
    );
  });

  return (
    <Fragment>
      {dateRangeCategories && (
        <DateRange
          dateRangeCategories={dateRangeCategories}
          dateRange={dateRange}
          setDateRange={setDateRange}
          filterByDateRange={setFilterByDateRange}
        />
      )}
      <Filters
        filterBy={filterBy}
        filterValue={filterValue}
        setFilter={setFilter}
        filters={filters}
        categories={headers}
      />
      <div className="max-w-full overflow-x-auto">
        <table className="table-auto mx-auto w-full border-teal-500 rounded text-sm text-gray-700">
          <tbody>
            <tr>{th}</tr>
            {fields}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default Table;
