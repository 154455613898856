import React, { useState, Fragment } from 'react';
import axios from '../../../../api/axios';
import BtnSpinner from '../../../../layout/BtnSpinner/BtnSpinner';

const OrderVideos = ({ dataSet, updateDataSet }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  console.log(dataSet);

  const submitHandler = async () => {
    setLoading(true);

    axios
      .patch(`/admin/orders/${dataSet.order._id}`, dataSet.order)
      .then((res) => {
        setUpdated(true);
        setError(false);
        updateDataSet('order', { ...res.data.data });
      })
      .catch(() => {
        setUpdated(false);
        setError(true);
      });

    setLoading(false);
  };

  const updVideosHandler = (field, value, index) => {
    const updData = [...dataSet.order.videos];

    updData[index][field] = value;

    updateDataSet('order', { videos: updData });
  };

  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-6">Order Videos</h3>
      <div className="card-lg">
        <div className="lg:flex flex-wrap">
          {dataSet.order.videos &&
            dataSet.order.videos.map((a, i) => (
              <div className="my-4 lg:mr-4" key={`Attachment_${i}`}>
                <h5 className="text-gray-700">Project</h5>
                <p className="text-gray-700">
                  {dataSet.projects.find((project) => project._id === a.projectId).name}
                </p>

                <h5 className="text-gray-700">Link</h5>
                <input
                  className="w-full"
                  onChange={(e) => updVideosHandler('link', e.target.value, i)}
                  value={a.link || ''}
                />
              </div>
            ))}
        </div>
        <button className="btn block mt-8 mx-auto" onClick={submitHandler}>
          {loading === 'info' ? <BtnSpinner /> : 'Update Attachments'}
        </button>
        {updated && <p className="admin-info">Order updated successfully</p>}
        {error && <p className="admin-error">Error updating order</p>}
      </div>
    </Fragment>
  );
};

export default OrderVideos;
