import React, { Fragment, useState } from 'react';
import BtnSpinner from '../../../../layout/BtnSpinner/BtnSpinner';
import downloadPdf from '../../../../utils/pdf';

const OrderRetirements = ({ data, getDataApi }) => {
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState('');
  const [error, setError] = useState('');

  const getDataApiHandler = async (endpoint, id, option) => {
    setLoading(option);
    const res = await getDataApi(endpoint + id);
    setLoading('');

    if (res) {
      setError('');
      setUpdated(option);
    } else {
      setUpdated('');
      setError(option);
    }
  };

  const downloadPdfHandler = async (endpoint, id, option) => {
    setLoading(option);
    const res = await downloadPdf(endpoint, id, option);
    setLoading('');

    if (res) {
      setError('');
      setUpdated(option);
    } else {
      setUpdated('');
      setError(option);
    }
  };
  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-2">Retirements</h3>
      {data.retirements.map((r, i) => (
        <div className="card-lg" key={`Retirement_${i}`}>
          <div className="flex flex-wrap lg:flex-no-wrap mx-auto lg:space-x-8 mb-6">
            <div className="w-full lg:w-1/2 mt-6 admin-details">
              <h3 className="mb-4 text-gray-700">Retirement Details</h3>

              <h5>Project</h5>
              <h3>
                <span>{data.projects.find((p) => p.prj_code === r.prj_code).name || 'N/A'}</span>
              </h3>

              <h5>Tonnes</h5>
              <h3>
                <span>{r.tonnes}</span>
              </h3>

              <h5>Status</h5>
              <h3>
                <span>{r.status ? 'Completed' : 'Backorder'}</span>
              </h3>
            </div>

            <div className="w-full lg:w-1/2 mt-6 admin-details">
              <h3 className="mb-4 text-gray-700">Options</h3>
              <div>
                {!r.status && data.order.status !== 'pastDue' && (
                  <Fragment>
                    <button
                      className="btn bg-danger mb-4"
                      type="submit"
                      onClick={() =>
                        getDataApiHandler(`/admin/retirements/reprocess/`, r._id, 'reprocess')
                      }
                    >
                      {loading === 'reprocess' ? <BtnSpinner /> : 'Reprocess Retirement'}
                    </button>
                    {updated === 'reprocess' && (
                      <p className="admin-info">Reprocess Markit retirement successfully</p>
                    )}
                    {error === 'reprocess' && (
                      <p className="admin-error">Error reprocessing Markit</p>
                    )}
                  </Fragment>
                )}
                <button
                  className="btn btn-teal mb-4"
                  type="submit"
                  onClick={() => downloadPdfHandler(`/admin/retirements/cert`, r._id, 'cert')}
                >
                  {loading === 'cert' ? <BtnSpinner /> : 'Download Certificate'}
                </button>
                {updated === 'cert' && (
                  <p className="admin-info">Generated certificate successfully</p>
                )}
                {error === 'cert' && <p className="admin-error">Error downloading certificate</p>}
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* {passMessage && <p className="text-center text-red-500">{passMessage}</p>} */}
    </Fragment>
  );
};

export default OrderRetirements;
