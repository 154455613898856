import React, { useState, useCallback, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { getDataApi, updateDataApi } from '../../../utils/api';
import csv from '../../../utils/csv';
import UserInfo from './UserInfo';
import UserPass from './UserPass';
import axios from '../../../api/axios';

const User = (props) => {
  const [dataSet, setDataSet] = useState({});
  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');
  const [updated, setUpdated] = useState('');

  const checkFieldsHandler = useCallback(() => {
    let updErrors = { ...errors };
    const checkfor = ['company', 'address1', 'country', 'city', 'zip', 'name', 'email'];
    for (const [key, value] of Object.entries(dataSet.user)) {
      if (
        (checkfor.includes(key) && (!value || value === '')) ||
        (checkfor.includes(key) && key === 'email' && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
      ) {
        updErrors[key] = true;
        setErrors(updErrors);
      } else {
        if (value !== '' && key in errors) {
          delete updErrors[key];
          setErrors(updErrors);
        }
      }
    }

    return updErrors;
  }, [dataSet, errors]);

  const getSetData = async (query) => {
    const user = await getDataApi(`${props.match.url}`, query);
    setDataSet({ user });
  };

  const updateDataSet = (field, data) => {
    const updData = { ...dataSet };
    updData[field] = { ...dataSet[field], ...data };
    setDataSet(updData);
  };

  const updateDataHandler = async (endpoint, option, id) => {
    const errors = checkFieldsHandler();
    if (isEmpty(errors)) {
      setLoading(option);
      const res = await updateDataApi(`/admin/${endpoint}/${id}`, dataSet[option]);
      setLoading(false);

      if (res) setUpdated(option);
      else setError(option);
    }
  };

  const resetPasswordHandler = (email) => {
    const option = 'password';
    setLoading(option);

    axios
      .post('auth/forgot', { email })
      .then(() => setUpdated(option))
      .catch(() => setError(option));

    setLoading(false);
  };

  const submitNewPasswordHandler = (password, id) => {
    const option = 'newpassword';
    setLoading(option);
    axios
      .post(`admin/users/password/${id}`, { password })
      .then(() => setUpdated(option))
      .catch(() => setError(option));

    setLoading(false);
  };

  const downloadCSVHandler = async () => {
    const headers = ['role', 'name', 'email', 'company', 'country', 'createdAt'];

    const data = [
      {
        role: dataSet.role,
        name: dataSet.name,
        email: dataSet.email,
        company: dataSet.company,
        country: dataSet.country,
        createdAt: dataSet.createdAt,
      },
    ];

    return csv(data, headers, 'user');
  };

  if (isEmpty(dataSet)) getSetData();

  return (
    <div>
      <h3 className="text-gray-700 text-center text-3xl my-2">User Details</h3>
      {!isEmpty(dataSet) && (
        <Fragment>
          <UserInfo
            data={dataSet}
            updateDataSet={updateDataSet}
            updateDataHandler={updateDataHandler}
            getDataApi={getDataApi}
            downloadCSVHandler={downloadCSVHandler}
            resetPasswordHandler={resetPasswordHandler}
            loading={loading}
            updated={updated}
            error={error}
            errors={errors}
          />
          <UserPass
            id={dataSet.user._id}
            submitNewPasswordHandler={submitNewPasswordHandler}
            loading={loading}
            updated={updated}
            error={error}
            errors={errors}
          />
        </Fragment>
      )}
    </div>
  );
};
export default User;
