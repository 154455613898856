import React, { Fragment } from 'react';

const Details = (props) => {
  return (
    <Fragment>
      <h1 className="mb-6">{props.modalData.title}</h1>
      <div className="content">
        {props.modalData.content.map((p) => (
          <p>{p}</p>
        ))}
      </div>
      {props.modalData.moreInfo && (
        <a
          className="text-teal-500 underline"
          href={props.modalData.moreInfo}
          target="_blank"
          rel="noopener noreferrer"
        >
          For more project information click here
        </a>
      )}
    </Fragment>
  );
};

export default Details;
