import React, { Fragment, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getDataApi } from '../../../utils/api';
import Table from '../../Table/Table';
import csv from '../../../utils/csv';
import FetchError from '../../Shared/FetchError';

const Orders = (props) => {
  const [dataSet, setDataSet] = useState({});
  const headers = [
    'tonnes',
    'price',
    'PPT',
    'currency',
    'projects',
    'extras',
    'type',
    'badge',
    'company',
    'user',
    'email',
    'status',
    'number',
    'reportLevel',
    'createdDate',
    'issuedDate',
  ];

  const getSetData = async (query) => {
    const orders = await getDataApi(`${props.match.url}`, query);
    const users = await getDataApi('/admin/users');
    const projects = await getDataApi('/admin/projects');
    const extras = await getDataApi('/admin/extras', {
      'orderId[exists]': true,
    });
    const badges = await getDataApi('/badges');

    const dataSet = orders.map((order) => {
      const user = users.find((user) => user._id === order.userId);
      const orderExtras = extras.filter((ext) => ext.orderId === order._id);

      order.createdDate = order.createdAt.substring(0, 10);
      order.issuedDate = order.issuedAt ? order.issuedAt.substring(0, 10) : 'N/A';
      order.type =
        order.orderType === 'bc'
          ? 'Calculate & Offset'
          : order.orderType === 'dp'
          ? 'Supporter Program'
          : 'Report Only';
      order.badge = badges.find((b) => b._id === order.badgeId).name || 'N/A';
      order.reportLevel = order.level;
      order.PPT = order.orderType !== 'ro' ? (order.price / order.tonnes).toFixed(2) : 'N/A';

      if (order.projects && order.projects.length > 0)
        order.projects = order.projects
          .map((project) => {
            return projects.find((p) => p._id === project).name;
          })
          .join('');
      else order.projects = 'N/A';

      if (orderExtras.length > 0) order.extras = orderExtras.map((extra) => extra.name).join('');

      if (!order.tonnes) order.tonnes = 'N/A';
      if (!order.extras) order.extras = 'N/A';

      return {
        ...order,
        user: user.name,
        email: user.email,
        company: user.company,
      };
    });
    setDataSet(dataSet);
    // .then(data => setDataSet(dataSet))
  };

  useEffect(() => {
    if (isEmpty(dataSet)) getSetData();
    // eslint-disable-next-line
  }, []);

  let activeElement = (
    <FetchError error="Error fetching or filtering data" message="please reload the page" />
  );

  if (!isEmpty(dataSet))
    activeElement = (
      <div>
        <h1 className="mb-8">Orders</h1>
        {!isEmpty(dataSet) && (
          <Fragment>
            <Table
              dataSet={dataSet}
              headers={headers}
              getSet={getSetData}
              setData={setDataSet}
              history={props.history}
              route={props.match.url}
              dateRangeCategories={['createdDate', 'issuedDate']}
            />
            <button className="btn btn-teal mt-4" onClick={() => csv(dataSet, headers, 'orders')}>
              Download CSV
            </button>
          </Fragment>
        )}
      </div>
    );

  return activeElement;
};
export default Orders;
