import React, { Fragment, useState } from 'react';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';
import { createDataApi } from '../../../utils/api';
import { useForm } from 'react-hook-form';

const UserAdd = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const createUserHandler = async (data) => {
    setLoading(true);
    data.passwordConfirm = data.password;
    const res = await createDataApi(`/admin/users`, data);
    setLoading(false);

    if (res) setUpdated(true);
    else setError(true);
  };

  return (
    <Fragment>
      <h1 className="mb-6 text-gray-700 text-center">Create User</h1>
      <div className="card-lg">
        <h3 className="mb-6 text-gray-700">User Details</h3>
        <form onSubmit={handleSubmit(createUserHandler)}>
          <div className="cols xl:space-x-4">
            <div className="xl:w-1/2 w-full text-center">
              <h5 className="text-teal-500 mt-4">Role</h5>
              <div className="relative w-full styled-select my-2 mt-2">
                <select
                  className="focus:outline-none focus:bg-white focus:border-gray-500"
                  name="role"
                  ref={register({ required: true })}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errors.role && <p className="error">Please select a role</p>}

              <h5 className="text-teal-500 mt-4">Name</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="name"
                ref={register({ required: true })}
                placeholder="Name"
              />
              {errors.name && <p className="error">Please enter a name</p>}

              <h5 className="text-teal-500 mt-4">Email</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="email"
                ref={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                placeholder="Email Address"
              />
              {errors.email && <p className="error">Please enter a valid email address</p>}

              <h5 className="text-teal-500 mt-4">Password</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="password"
                ref={register({
                  required: true,
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                })}
                placeholder="Password"
              />
              {errors.password && (
                <p className="error">
                  Minimum 8 characters. One uppercase, one special, one number
                </p>
              )}

              <h5 className="text-teal-500 mt-4">Company</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="company"
                ref={register({ required: true })}
                placeholder="Company Name"
              />
              {errors.company && <p className="error">Please enter a company name</p>}

              <h5 className="text-teal-500 mt-4">Position</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="position"
                ref={register()}
                placeholder="Position (Optional)"
              />
              {errors.position && <p className="error">Please enter a position</p>}
            </div>
            <div className="xl:w-1/2 w-full text-center">
              <h5 className="text-teal-500 mt-4">Country</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="country"
                ref={register({ required: true })}
                placeholder="Country"
              />
              {errors.country && <p className="error">Please enter a country</p>}

              <h5 className="text-teal-500 mt-4">State</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="state"
                ref={register()}
                placeholder="State (Optional)"
              />
              {errors.state && <p className="error">Please enter a state</p>}

              <h5 className="text-teal-500 mt-4">City</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="city"
                ref={register({ required: true })}
                placeholder="City"
              />
              {errors.city && <p className="error">Please enter a city</p>}

              <h5 className="text-teal-500 mt-4">Address 1</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="address1"
                ref={register({ required: true })}
                placeholder="Address Line 1"
              />
              {errors.address1 && <p className="error">Please enter a address</p>}

              <h5 className="text-teal-500 mt-4">Address 2</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="address2"
                ref={register()}
                placeholder="Address Line 2 (Optional)"
              />
              {errors.address2 && <p className="error">Please enter a address</p>}

              <h5 className="text-teal-500 mt-4">Zip Code</h5>
              <input
                className="mt-2 mx-auto w-full"
                type="text"
                name="zip"
                ref={register({ required: true })}
                placeholder="Zip Code"
              />
              {errors.zip && <p className="error">Please enter a zip code</p>}
            </div>
          </div>
          <button className="btn block mt-8 mx-auto" type="submit">
            {loading ? <BtnSpinner /> : 'Create User'}
          </button>
          {updated && <p className="admin-info">User created successfully</p>}
          {error && <p className="admin-error">Error creating user</p>}
        </form>
      </div>
    </Fragment>
  );
};

export default UserAdd;
