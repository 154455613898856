
import * as actypes from '../actypes'

export const resultsReset = () => {
  return {
    type: actypes.RESULTS_RESET
  }
}

export const resultsSet = (payload) => {
  return {
    type: actypes.RESULTS_SET,
    payload
  }
}