import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {}
// const initState = {
//   status: 'error',
//   page: 'home',
//   message: 'Error',
//   button: 'Homepage',
//   data: '',
//   email: 'You done Fed up'
// }



const thanksSet = (state, action) => {
  return updateObject(state, action.payload)
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.THANKS_SET: return thanksSet(state, action)
    case actypes.THANKS_RESET: return initState
    default: return state
  }
}

export default reducer