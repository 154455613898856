import * as actions from '../actypes'

const initState = {
  modal: false,
  modalType: null,
  modalData: null
}

const modalOpen = (state, action) => {
  document.body.style.overflow = 'hidden';
  return { modal: true, modalType: action.payload.modalType, modalData: action.payload.modalData }
}

const modalClose = (state, action) => {
  document.body.style.overflow = 'unset';
  return initState
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.MODAL_OPEN: return modalOpen(state, action)
    case actions.MODAL_CLOSE: return modalClose(state, action)
    default: return state
  }
}

export default reducer