const csv = (dataSet, headers, filename) => {
  let csvContent = "data:text/csv;charset=utf-8,"
    + headers.join(',')
    + ("\n")
    + dataSet.map(e => {
      return headers.map(h => e[h]).join(',')
    }).join("\n")


  let encodedUri = encodeURI(csvContent)
  let link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", `${filename}-${Date.now()}.csv`)
  document.body.appendChild(link)

  link.click()
}

export default csv