import React, { Fragment, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getDataApi } from '../../../utils/api';
import Table from '../../Table/Table';
import csv from '../../../utils/csv';
import FetchError from '../../Shared/FetchError';

const Extras = (props) => {
  const [dataSet, setDataSet] = useState({});
  const headers = ['name', 'status', 'projectName', 'assignedToOrder'];

  const getSetData = async (query) => {
    let extras = await getDataApi(`${props.match.url}`, query);
    const projects = await getDataApi(`admin/projects`);

    extras = extras.map((e) => {
      if (e.projectId) {
        const project = projects.find((project) => project._id === e.projectId);
        e.projectName = project ? project.name : '';
      }
      e.assignedToOrder = e.orderId ? 'Yes' : 'No';

      return e;
    });
    setDataSet(extras);
  };

  useEffect(() => {
    if (isEmpty(dataSet)) getSetData();
    // eslint-disable-next-line
  }, []);

  let activeElement = (
    <div>
      <h1 className="mb-8">Extras</h1>
      {!isEmpty(dataSet) ? (
        <Fragment>
          <Table
            dataSet={dataSet}
            headers={headers}
            getSet={getSetData}
            setData={setDataSet}
            history={props.history}
            route={props.match.url}
            // dateRangeCategories={['createdDate', 'issuedDate']}
          />
        </Fragment>
      ) : (
        <FetchError error="Error fetching or filtering data" message="please reload the page" />
      )}
      <div className="flex space-x-4 items-center mt-4">
        {!isEmpty(dataSet) && (
          <button className="btn btn-teal" onClick={() => csv(dataSet, headers, 'extras')}>
            Download CSV
          </button>
        )}
        <button className="btn btn-danger" onClick={() => props.history.push(`add/extras`)}>
          Add Extra
        </button>
      </div>
    </div>
  );

  return activeElement;
};
export default Extras;
