import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {}
// const initState = {

//   "results": {
//     "fuel": {
//       "name": "Scope 1: Direct Fuels & Refrigerants",
//       "forms": {
//         "direct-fuel-usage": 0.00047999999999999996,
//         "company-owned-vehicl": 0.0044,
//         "refrigerant-gas-purc": 0.003
//       },
//       "total": 0.007880000000000002
//     }
//   },
//   "byLevel": {
//     "1": 120.007880000000000002,
//     "2": 634.007880000000000002,
//     "3": 1920.007880000000000002
//   }
// }



const resultsSet = (state, action) => {
  return updateObject(state, action.payload)
}


const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.RESULTS_SET: return resultsSet(state, action)
    case actypes.RESULTS_RESET: return initState
    default: return state
  }
}

export default reducer