import { RESET_STORE } from '../actypes'

export const resetStore = () => {
  return {
    type: RESET_STORE
  }
}

export {
  modalOpen,
  modalClose
} from './modal'

export {
  initPage,
  switchPage,
  jumpPage,
  setValid
} from './pages'

export {
  formInit,
  formSetOption,
  formDelete,
  formCalculate
} from './forms'

export {
  authInit,
  authCheckState,
  authLogout
} from './auth'

export {
  resultsSet,
  resultsReset
} from './results'

export {
  badgesInit,
  badgesReset,
  // badgesUpdate,
  badgesSelected,
  badgesSave
} from './badges'

export {
  projectsInit,
  projectsSelected,
  projectsOptions,
  projectsReset
} from './projects'

export {
  extrasInit,
  extrasSet,
  extrasReset
} from './extras'

export {
  donorSet,
  donorInit,
  // donorReset,
} from './donor'

export {
  fetchOptions
} from './options'

export {
  thanksSet
} from './thanks'

