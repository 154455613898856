import * as actypes from '../actypes'

export const pagesReset = () => {
  return {
    type: actypes.PAGES_RESET
  }
}

export const initPage = (app) => {
  return {
    type: actypes.PAGES_INIT,
    app: app,
  }
}

export const switchPage = (direction) => {
  return dispatch => {
    if (direction === 'p') dispatch(prevPage())
    if (direction === 'n') dispatch(nextPage('next'))
    if (direction === 's') dispatch(nextPage('skip'))
  }
}

export const jumpPage = (page) => {
  return {
    type: actypes.PAGES_JUMP,
    page
  }
}

const nextPage = (method) => {
  return {
    type: actypes.PAGES_NEXT,
    method
  }
}

const prevPage = () => {
  return {
    type: actypes.PAGES_PREV,
  }
}

export const setValid = (status) => {
  return {
    type: actypes.PAGES_VALID,
    status
  }
}