import React, { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import axios from '../../api/axios';

import * as actions from '../../store/actions';

const Login = (props) => {
  const [message, setMessage] = useState('');
  const [forgot, setForgot] = useState(false);

  const resetPasswordHandler = () => {
    axios.post('/auth/forgot', { email: email.current });
    setMessage('Password reset link sent to email');
  };

  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const dispatch = useDispatch();

  const onLogin = (payload) => dispatch(actions.authInit(payload, 'login'));
  const loginError = useSelector((state) => state.auth.error);

  const email = useRef({});
  email.current = watch('email', '');

  const changeModalHandler = () => {
    dispatch(actions.modalClose({ modalType: 'signin' }));
    dispatch(actions.modalOpen({ modalType: 'signup', modalData: { forceOpen: true } }));
  };

  return (
    <Fragment>
      <h1 className="mb-2 text-gray-700">Login</h1>
      <p className="text-gray-700 mb-6">
        Don't have an account?{' '}
        <button className="font-bold text-orange-500" onClick={changeModalHandler}>
          Sign Up instead
        </button>
      </p>
      <form onSubmit={handleSubmit(onLogin)}>
        <input
          className="mt-4 w-full"
          type="text"
          name="email"
          ref={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
          placeholder="Email Address"
        />
        {errors.email && <p className="error">Please enter a valid email address</p>}

        {!forgot && (
          <Fragment>
            <input
              className="mt-4 w-full"
              type="password"
              name="password"
              ref={register({ required: true })}
              placeholder="Password"
            />
            {errors.password && <p className="error">Please enter your password</p>}
            <div>
              <button className="btn block mt-8 mx-auto text-xl px-16" type="submit">
                Sign In
              </button>
              <button className="btn block mt-8 mx-auto text-xl px-16" onClick={() => {
                setValue('email', 'guest@standfortrees.org')
                setValue('password', 'Guestuser123')
                handleSubmit(onLogin)
              }}>
                Enter as a guest
              </button>
            </div>
          </Fragment>
        )}
      </form>
      {loginError && <p className="mt-4 text-red-500 text-center">{loginError}</p>}
      {message && <p className="mt-4 text-teal-500 text-center">{message}</p>}
      {!forgot && (
        <button
          className="text-gray-600 text-sm font-light mt-2 underline"
          onClick={() => setForgot(true)}
        >
          Forgot password? Click here to recover
        </button>
      )}

      {forgot && (
        <Fragment>
          <button
            className="btn btn-teal mt-4 mx-auto text-xl px-16"
            onClick={resetPasswordHandler}
          >
            Reset Password
          </button>
          <button
            className="text-gray-600 text-sm font-light mt-2 underline"
            onClick={() => setForgot(false)}
          >
            Go back to Signin
          </button>
        </Fragment>
      )}
      <p className="text-gray-700 mt-16">
        See available projects{' '}
        <a
          className="font-bold text-teal-500"
          href="/projects"
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>{' '}
        For any questions, contact us at{' '}
        <a href="mailto:business@standfortrees.org">business@standfortrees.org</a>.
      </p>
    </Fragment>
  );
};

export default Login;
