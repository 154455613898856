import * as actypes from '../actypes'
import { modalClose } from './modal'

import axios from '../../api/axios'
import { pagesReset } from './pages'
// import { badgesReset } from './badges'
// import { extrasReset } from './extras'
// import { formsReset } from './forms'


export const authSuccess = (data) => {
  return {
    type: actypes.AUTH_SUCCESS,
    email: data.email,
    token: data.token,
    name: data.name
  }
}

export const authFail = (err) => {
  console.error(err)
  return {
    type: actypes.AUTH_FAIL,
    error: err
  }
}

export const authLogout = () => {
  return dispatch => {
    dispatch(pagesReset())
    dispatch(_authLogout())
  }
}

const _authLogout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('email')
  localStorage.removeItem('name')
  localStorage.removeItem('expires')
  return {
    type: actypes.AUTH_LOGOUT
  }
}

export const authInit = (payload, method) => {
  console.log(payload)
  return dispatch => {
    axios.post(`/auth/${method}`, payload)
      .then(res => {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('email', res.data.data.email)
        localStorage.setItem('name', res.data.data.name)
        localStorage.setItem('expires', res.data.expires)

        dispatch(authSuccess({ email: res.data.data.email, name: res.data.data.name, token: res.data.token }))
        dispatch(modalClose())
        // dispatch(checkAuthTimeout(res.data.expiresIn))
      })
      .catch(err => {
        console.error(err.response.data.message)
        dispatch(authFail(err.response.data.message))
      })
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token')
    const expires = localStorage.getItem('expires')
    if (!token || Date.now() > expires) {
      dispatch(authLogout())
    } else {
      dispatch(authSuccess({ name: localStorage.getItem('name'), email: localStorage.getItem('email'), token }))
    }
  }
}
