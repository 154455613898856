export const updateObject = (oldObj, updVals) => {
  return {
    ...oldObj,
    ...updVals
  }
}

export const removeObject = (oldObj, key) => {
  let updState = { ...oldObj }
  delete updState[key]

  return updState

}

