import * as actypes from '../actypes'
import { combineReducers } from 'redux';

import authReducer from './auth'
import modalReducer from './modal'
import pagesReducer from './pages'
import formsReducer from './forms'
import resultsReducer from './results'
import badgesReducer from './badges'
import projectsReducer from './projects'
import donorReducer from './donor'
import optionsReducer from './options'
import extrasReducer from './extras'
import thanksReducer from './thanks'

const appReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  pages: pagesReducer,
  forms: formsReducer,
  results: resultsReducer,
  badges: badgesReducer,
  projects: projectsReducer,
  donor: donorReducer,
  options: optionsReducer,
  extras: extrasReducer,
  thanks: thanksReducer
})

const rootReducer = (state, action) => {
  switch (action.type) {
    // case actypes.AUTH_LOGOUT: return appReducer(undefined, action)
    case actypes.RESET_STORE: return appReducer({ auth: state.auth }, action)
    default: return appReducer(state, action)
  }
}

export default rootReducer