import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import axios from '../../../api/axios';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';

const ProjectInfo = ({ dataSet, updateDataSet }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const imageHandler = (data) => {
    setLoading(true);

    const formData = new FormData();
    if (data.image.length > 0) formData.append('image', data.image[0]);

    if (data.cert.length > 0) formData.append('cert', data.cert[0]);

    // for (const [field, value] of Object.entries(data)) {
    //   if (field !== 'image' && field !== 'cert')
    //     formData.append(field, value)
    // }

    formData.append('path', `projects/${dataSet.project.prj_code}`);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    formData.append('_method', 'PATCH');

    axios
      .post(`/admin/projects/${dataSet.project._id}`, formData, config)
      .then((res) => {
        setUpdated(true);
        setError(false);
        updateDataSet('project', { ...res.data.data });
      })
      .catch(() => {
        setUpdated(false);
        setError(true);
      });

    setLoading(false);
  };

  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-6">Project Images</h3>
      <form onSubmit={handleSubmit(imageHandler)}>
        <div className="card-lg">
          <div className="lg:flex lg:space-x-8">
            <div className="w-full lg:w-1/2">
              <img
                src={dataSet.project.image}
                alt="Thumbnail for project"
                className="h-64 mx-auto"
              />
              <h5 className="text-teal-500 mt-4 text-center">Project Image</h5>
              <input
                type="file"
                accept="image/png, image/jpeg"
                className="my-2 w-full"
                name="image"
                ref={register()}
              />
              {errors.image && <p className="error">Please enter image</p>}
            </div>
            <div className="w-full lg:w-1/2">
              <img
                src={dataSet.project.cert}
                alt="Certificate for project"
                className="h-64 mx-auto"
              />
              <h5 className="text-teal-500 mt-4 text-center">Project Certificate</h5>
              <input
                type="file"
                accept="image/png, image/jpeg"
                className="my-2 w-full"
                name="cert"
                ref={register()}
              />
              {errors.cert && <p className="error">Please enter certificate</p>}
            </div>
          </div>

          <button className="btn block mt-8 mx-auto" type="submit">
            {loading ? <BtnSpinner /> : 'Update Images'}
          </button>
          {updated && <p className="admin-info">Images updated successfully</p>}
          {error && <p className="admin-error">Error updating images</p>}
        </div>
      </form>
    </Fragment>
  );
};

export default ProjectInfo;
