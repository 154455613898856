import React, { Fragment, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { getDataApi } from '../../../utils/api'
import Table from '../../Table/Table'
import csv from '../../../utils/csv'
import FetchError from '../../Shared/FetchError'

const Projects = (props) => {
  const [dataSet, setDataSet] = useState({})
  const headers = [
    'name',
    'status',
    'prj_code',
    'country',
  ]

  const getSetData = async (query) => {
    let projects = await getDataApi(`${props.match.url}`, query)
    setDataSet(projects)
  }

  useEffect(() => {
    if (isEmpty(dataSet))
      getSetData()
    // eslint-disable-next-line
  }, [])

  let activeElement = <FetchError error="Error fetching or filtering data" message="please reload the page" />

  if (!isEmpty(dataSet))
    activeElement = <div>
      <h1 className="mb-8">Projects</h1>
      {!isEmpty(dataSet) &&
        <Fragment>

          <Table
            dataSet={dataSet}
            headers={headers}
            getSet={getSetData}
            setData={setDataSet}
            history={props.history}
            route={props.match.url}
          // dateRangeCategories={['createdDate', 'issuedDate']}
          />
          <div className="flex space-x-4 items-center mt-4">
            <button className="btn btn-teal" onClick={() => csv(dataSet, headers, 'projects')}>Download CSV</button>
            <button className="btn btn-danger" onClick={() => props.history.push(`add/projects`)}>Add Project</button>
          </div>
        </Fragment>}
    </div>

  return activeElement

}
export default Projects

