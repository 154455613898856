import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Dropdown = (props) => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  const name = useSelector((state) => state.auth.name);

  const signOutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('expires');
    window.location.replace('/');
  };

  return (
    <div
      className="relative right-0 top-0 h-full"
      onMouseEnter={() => setMenu(!menu)}
      onMouseLeave={() => setMenu(!menu)}
      onClick={() => setMenu(!menu)}
    >
      <div className="flex h-full items-center justify-center hover:bg-teal-800 p-4 text-white">
        <svg
          className="w-8 h-8 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <div className="ml-2">
          <p className="hidden md:block font-bold">
            Hello, {name.length > 12 ? name.substring(0, 12) + '...' : name}
          </p>
          {/* <p className="hidden md:block text-xs font-light">My Account</p> */}
        </div>
      </div>
      {menu ? (
        <div className="absolute right-0 w-40 lg:w-full bg-teal-500 text-white  rounded-b">
          <ul>
            {/* <li className="hover:bg-teal-800" onClick={() => window.location.replace('/user/account')}><p className="text-white py-4 px-6 block text-sm no-underline" >My Account</p></li>
            <li className="hover:bg-teal-800" onClick={() => window.location.replace('/user/badges')}><p className="text-white py-4 px-6 block text-sm no-underline" >My Badges &amp; Assets</p></li> */}
            <li className="hover:bg-teal-800" onClick={() => history.push('/user/account')}>
              <p className="text-white py-4 px-6 block text-sm no-underline">My Account</p>
            </li>
            <li className="hover:bg-teal-800" onClick={() => history.push('/user/badges')}>
              <p className="text-white py-4 px-6 block text-sm no-underline">
                My Badges &amp; Assets
              </p>
            </li>
            <li className="hover:bg-teal-800 rounded-b" onClick={signOutHandler}>
              <p className="py-4 px-6 block text-white text-sm no-underline">Sign Out</p>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
