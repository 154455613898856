import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logoImg from '../../assets/img/logo-white.png';
import logoTrees from '../../assets/img/logo-trees.png';
import Burger from './Burger';

const Header = (props) => {
  const history = useHistory();

  const signOutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('expires');
    window.location.replace('/');
  };

  return (
    <div className="w-full border-white border-b-4 bg-teal-500 text-white">
      <div className="flex justify-between items-center h-20">
        <img
          className="w-48 hidden md:block"
          src={logoImg}
          alt="sft-logo"
          onClick={() => history.push('/')}
        />
        <img
          className="h-10 md:hidden ml-4"
          src={logoTrees}
          alt="logo-mobile"
          onClick={() => history.push('/')}
        />
        {props.auth.token &&
          (window.innerWidth > 767 ? (
            <div className="flex">
              {/* <h3 className="text-lg font-bold cursor-pointer p-6 hover:bg-teal-800" onClick={() => props.onEnterApp('bc')}>Calculate & Offset</h3>
              <h3 className="text-lg font-bold cursor-pointer p-6 hover:bg-teal-800" onClick={() => props.onEnterApp('dp')}>Supporter Program</h3> */}
              <h3
                className="flex items-center text-lg font-bold cursor-pointer px-6 hover:bg-teal-800 h-20"
                onClick={() => history.push('/user/account')}
              >
                My Account
              </h3>
              <h3
                className=" flex items-center text-lg font-bold cursor-pointer px-6 hover:bg-teal-800 h-20"
                onClick={signOutHandler}
              >
                Logout
              </h3>
            </div>
          ) : (
            <Burger {...props} />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.pages.app,
});

export default connect(mapStateToProps)(Header);
