import React, { useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import axios from '../../../api/axios';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';

const ProjectBundles = ({ dataSet, updateDataSet }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const submitHandler = async () => {
    setLoading(true);

    axios
      .patch(`/admin/projects/${dataSet.project._id}`, dataSet.project)
      .then((res) => {
        setUpdated(true);
        setError(false);
        updateDataSet('project', { ...res.data.data });
      })
      .catch(() => {
        setUpdated(false);
        setError(true);
      });

    setLoading(false);
  };

  const updBundlesHandler = (field, value, index) => {
    const updData = [...dataSet.project.bundles];

    updData[index][field] = value;

    updateDataSet('project', { bundles: updData });
  };
  const removeBundlesHandler = (index) => {
    const updData = [...dataSet.project.bundles];

    updData.splice(index, 1);

    updateDataSet('project', { bundles: updData });
  };

  const addBundlesHandler = () => {
    const updData = [...dataSet.project.bundles];

    updData.push({ level: 1, link: '' });

    updateDataSet('project', { bundles: updData });
  };

  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-6">Content &amp; Reporting Photos</h3>
      <div className="card-lg">
        <div className="lg:flex flex-wrap">
          {dataSet.project.bundles &&
            !isEmpty(dataSet.project.bundles) &&
            dataSet.project.bundles.map((a, i) => (
              <div className="my-4 lg:mr-2" key={`Attachment_${i}`}>
                <h5>Link</h5>
                <input
                  className="w-full"
                  onChange={(e) => updBundlesHandler('link', e.target.value, i)}
                  value={a.link || ''}
                />

                <h5>Year</h5>
                <input
                  type="number"
                  className="w-full"
                  onChange={(e) => updBundlesHandler('year', e.target.value, i)}
                  value={a.year || ''}
                />

                <h5>Level</h5>
                <div className="relative w-full styled-select">
                  <select
                    className="focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => updBundlesHandler('level', e.target.value, i)}
                    value={a.level || 2}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <button
                  className="btn btn-sm btn-gray mt-4"
                  onClick={() => removeBundlesHandler(i)}
                >
                  Remove Bundle
                </button>
              </div>
            ))}
        </div>
        <button className="btn btn-teal mt-4" onClick={addBundlesHandler}>
          Add Bundle
        </button>
        <button className="btn block mt-8 mx-auto" onClick={submitHandler}>
          {loading === 'info' ? <BtnSpinner /> : 'Update Bundles'}
        </button>
        {updated && <p className="admin-info">Proiect updated successfully</p>}
        {error && <p className="admin-error">Error updating project</p>}
      </div>
    </Fragment>
  );
};

export default ProjectBundles;
