import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from '../../../api/axios'
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner'

const ExtraInfo = (
  { dataSet,
    updateDataSet,
  }) => {

  const history = useHistory()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updated, setUpdated] = useState(false)

  const { register, handleSubmit, errors } = useForm({ defaultValues: dataSet.extra })

  const submitHandler = async (data) => {
    setLoading(true)

    axios.patch(`/admin/extras/${dataSet.extra._id}`, data)
      .then((res) => {
        setUpdated(true)
        setError(false)
        updateDataSet('extra', { ...res.data.data })
      })
      .catch(() => {
        setUpdated(false)
        setError(true)
      })

    setLoading(false)
  }

  return (
    <div className="flex flex-wrap lg:flex-no-wrap mx-auto shadow-md rounded py-4 px-8 lg:space-x-8 mb-6">
      <form className="w-full" onSubmit={handleSubmit(submitHandler)}>
        <h5 className="text-teal-500">Assigned To Order</h5>
        <h5 className="text-gray-600">{dataSet.extra.order ? dataSet.extra.order.number : 'N/A'}</h5>
        {dataSet.extra.order && <button className="btn btn-teal btn-sm mb-4" onClick={() => history.push(`/admin/orders/${dataSet.extra.order._id}`)}>Go To Order Details</button>}

        {/* <h5 className="text-teal-500">Video has been requested</h5>
        <h5 className="text-gray-600 mb-4">{dataSet.extra.video ? 'Yes' : 'No'}</h5> */}

        <h5 className="text-teal-500">Name</h5>
        <input type="text" className="my-2 w-full" name="name" ref={register({ required: true })} />
        {errors.name && <p className="error">Please enter name</p>}

        <h5 className="text-teal-500">Status</h5>
        <div className="relative w-full styled-select my-2">

          <select className="focus:outline-none focus:bg-white focus:border-gray-500"
            name="status"
            ref={register()}>
            <option value="pending">Pending Attachments</option>
            <option value="completed">Completed Attachments</option>
            <option value="semicompleted">Semi Completed Attachments</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
          </div>
        </div>

        <button className="btn block mt-8 mx-auto" type="submit">{loading ? <BtnSpinner /> : 'Save Details'}</button>
        {updated && <p className="admin-info">Extra updated successfully</p>}
        {error && <p className="admin-error">Error updating extra</p>}
      </form>

    </div>

  )
}

export default ExtraInfo