import React from 'react';

const detailsTable = ({ details, headers }) => {
  const insertAt = (array, index, ...elements) => {
    array.splice(index, 0, ...elements);
  };

  const positions = [
    'name',
    'email',
    'position',
    'company',
    'country',
    'city',
    'state',
    'address1',
    'address2',
    'zip',
  ];

  const tContent = [];

  for (let [key, value] of Object.entries(details)) {
    if (value === 'ro') value = 'Detailed Report';
    if (value === 'dp') value = 'Supporter Badge';
    if (value === 'bc') value = 'Calculate & Offset';
    if (key === 'orderType') key = 'Order Type';
    if (key === 'number') key = 'Order Number';

    const data = (
      <tr key={key + Math.random()}>
        <td className="border-b border-teal-200 p-2 capitalize" key={key}>
          {key}
        </td>
        <td className="border-b border-teal-200 p-2 text-right" key={key + Math.random()}>
          {value}
        </td>
      </tr>
    );

    if (positions.indexOf(key) >= 0) insertAt(tContent, positions.indexOf(key), data);
    else tContent.push(data);
  }

  return (
    <table className="table-auto mx-auto w-full border-teal-500 rounded text-sm text-gray-700">
      <tbody>
        <tr>
          <th className="p-2 bg-teal-500 text-white text-left" key="details">
            Details
          </th>
          <th className="p-2 bg-teal-500 text-white text-right" key="values">
            Values
          </th>
        </tr>
        {tContent}
      </tbody>
    </table>
  );
};

export default detailsTable;
