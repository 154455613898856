import * as actypes from '../actypes'
import axios from '../../api/axios'
import { authLogout } from './auth'

export const projectsReset = () => {
  return {
    type: actypes.PROJECTS_RESET
  }
}

export const projectsInit = () => {
  return (dispatch, getState) => {
    if (localStorage.getItem('token')) {
      axios.get(`/projects`)
        .then(res => {
          dispatch(projectsSet(res.data.projects))
        })
        .catch(err => {
          console.error(err)
        })
    }
    else
      dispatch(authLogout())
  }
}

export const projectsSet = (payload) => {
  return {
    type: actypes.PROJECTS_SET,
    payload
  }
}

export const projectsSelected = (payload) => {
  return {
    type: actypes.PROJECTS_SELECTED,
    payload
  }
}

export const projectsOptions = (payload) => {
  return {
    type: actypes.PROJECTS_OPTIONS,
    payload
  }
}