import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {
  badges: [],
  selected: {},
}

// const initState = {
//   "badges": [
//     {
//       "donationParams": {
//         "req": false
//       },
//       "app": "bc",
//       "donation": false,
//       "_id": "5f45678be612fa5d925b7577",
//       "image": "https://sftcdn.sfo2.cdn.digitaloceanspaces.com/badging-program/climate-helper.jpg",
//       "slug": "climate-helper",
//       "name": "Climate Helper",
//       "level": 1,
//       "currency": [
//         {
//           "slug": "USD",
//           "symbol": "$",
//           "_id": "5f4568a04bb7b15ee5181db0",
//           "price": 12
//         },
//         {
//           "slug": "EUR",
//           "symbol": "€",
//           "_id": "5f4568a04bb7b15ee5181db1",
//           "price": 11
//         },
//         {
//           "slug": "GBP",
//           "symbol": "£",
//           "_id": "5f4568a04bb7b15ee5181db2",
//           "price": 10
//         },
//         {
//           "slug": "COP",
//           "symbol": "$",
//           "_id": "5f4568a04bb7b15ee5181db3",
//           "price": 4700
//         }
//       ],
//       "__v": 0,
//       "setCurrency": "USD",
//       "setPrice": 1440.09456
//     },
//     {
//       "donationParams": {
//         "req": false
//       },
//       "app": "bc",
//       "donation": false,
//       "_id": "5f4568de4bb7b15ee5181db4",
//       "image": "https://sftcdn.sfo2.cdn.digitaloceanspaces.com/badging-program/climate-guardian.jpg",
//       "slug": "climate-guardian",
//       "name": "Climate Guardian",
//       "level": 2,
//       "currency": [
//         {
//           "slug": "USD",
//           "symbol": "$",
//           "_id": "5f4568de4bb7b15ee5181db5",
//           "price": 12
//         },
//         {
//           "slug": "EUR",
//           "symbol": "€",
//           "_id": "5f4568de4bb7b15ee5181db6",
//           "price": 11
//         },
//         {
//           "slug": "GBP",
//           "symbol": "£",
//           "_id": "5f4568de4bb7b15ee5181db7",
//           "price": 10
//         },
//         {
//           "slug": "COP",
//           "symbol": "$",
//           "_id": "5f4568de4bb7b15ee5181db8",
//           "price": 4700
//         }
//       ],
//       "__v": 0,
//       "setCurrency": "USD",
//       "setPrice": 7608.09456
//     },
//     {
//       "donationParams": {
//         "req": false
//       },
//       "app": "bc",
//       "donation": false,
//       "_id": "5f4568ec4bb7b15ee5181db9",
//       "image": "https://sftcdn.sfo2.cdn.digitaloceanspaces.com/badging-program/climate-champion.jpg",
//       "slug": "climate-champion",
//       "name": "Climate Champion",
//       "level": 3,
//       "currency": [
//         {
//           "slug": "USD",
//           "symbol": "$",
//           "_id": "5f4568ec4bb7b15ee5181dba",
//           "price": 12
//         },
//         {
//           "slug": "EUR",
//           "symbol": "€",
//           "_id": "5f4568ec4bb7b15ee5181dbb",
//           "price": 11
//         },
//         {
//           "slug": "GBP",
//           "symbol": "£",
//           "_id": "5f4568ec4bb7b15ee5181dbc",
//           "price": 10
//         },
//         {
//           "slug": "COP",
//           "symbol": "$",
//           "_id": "5f4568ec4bb7b15ee5181dbd",
//           "price": 4700
//         }
//       ],
//       "__v": 0,
//       "setCurrency": "USD",
//       "setPrice": 23040.094559999998
//     },
//     {
//       "donationParams": {
//         "req": true,
//         "min": 20,
//         "max": 200
//       },
//       "app": "bc",
//       "donation": true,
//       "_id": "5f45693b4bb7b15ee5181dbe",
//       "image": "https://sftcdn.sfo2.cdn.digitaloceanspaces.com/badging-program/climate-hero.jpg",
//       "slug": "climate-hero",
//       "name": "Climate Hero",
//       "level": 3,
//       "currency": [
//         {
//           "slug": "USD",
//           "symbol": "$",
//           "_id": "5f45693b4bb7b15ee5181dbf",
//           "price": 12
//         },
//         {
//           "slug": "EUR",
//           "symbol": "€",
//           "_id": "5f45693b4bb7b15ee5181dc0",
//           "price": 11
//         },
//         {
//           "slug": "GBP",
//           "symbol": "£",
//           "_id": "5f45693b4bb7b15ee5181dc1",
//           "price": 10
//         },
//         {
//           "slug": "COP",
//           "symbol": "$",
//           "_id": "5f45693b4bb7b15ee5181dc2",
//           "price": 4700
//         }
//       ],
//       "__v": 0,
//       "setDonation": 20,
//       "setCurrency": "USD",
//       "setPrice": 23040.094559999998,
//       "setTonnes": 1920.00788
//     }
//   ],
//   "selected": "5f45693b4bb7b15ee5181dbe"
// }

const badgesSet = (state, action) => {
  const updState = { ...state }
  updState.badges = action.payload
  return updateObject(state, updState)
}

const badgesSelected = (state, action) => {
  const updState = { ...state }
  updState.selected = { ...state.selected, ...action.payload }
  return updateObject(state, updState)
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.BADGES_RESET: return initState
    case actypes.BADGES_SET: return badgesSet(state, action)
    case actypes.BADGES_SELECTED: return badgesSelected(state, action)
    default: return state
  }
}

export default reducer