import React, { useState } from 'react';

const Burger = (props) => {
  const [menu, setMenu] = useState(false);

  return (
    <div className="relative right-0 top-0 h-full" onClick={() => setMenu(!menu)}>
      <div className="flex h-full items-center justify-center bg-orange-500 p-4 text-white">
        <svg
          className="w-8 h-8 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      </div>
      {menu ? (
        <div className="absolute right-0 w-48 lg:w-full bg-teal-900 text-white font-bold rounded-b">
          {!props.auth.token ? (
            <ul>
              <li className="hover:bg-teal-800" onClick={props.onSignUp}>
                <p className="cursor-pointer text-white py-4 px-6 block text-sm no-underline">
                  Sign Up
                </p>
              </li>
              <li className="hover:bg-teal-800 rounded-b" onClick={props.onSignIn}>
                <p className="cursor-pointer py-4 px-6 block text-white text-sm no-underline">
                  Log In
                </p>
              </li>
            </ul>
          ) : (
            <ul>
              <li className="hover:bg-teal-800" onClick={() => props.onEnterApp('bc')}>
                <p className="cursor-pointer text-white py-4 px-6 block text-sm no-underline">
                  Calculate & Offset
                </p>
              </li>
              <li className="hover:bg-teal-800" onClick={() => props.onEnterApp('dp')}>
                <p className="cursor-pointer text-white py-4 px-6 block text-sm no-underline">
                  Supporter Program
                </p>
              </li>
              <li
                className="hover:bg-teal-800 rounded-b"
                onClick={() => {
                  window.location.replace('/user/account');
                }}
              >
                <p className="cursor-pointer py-4 px-6 block text-white text-sm no-underline">
                  My Account
                </p>
              </li>
            </ul>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Burger;
