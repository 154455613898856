import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import axios from '../../../api/axios';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';

const ProjectMoreInfo = ({ dataSet, updateDataSet }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const pdfHandler = (data) => {
    setLoading(true);

    const formData = new FormData();
    if (data.moreInfo.length > 0) formData.append('moreInfo', data.moreInfo[0]);

    formData.append('path', `projects/${dataSet.project.prj_code}`);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    formData.append('_method', 'PATCH');

    axios
      .post(`/admin/projects/${dataSet.project._id}`, formData, config)
      .then((res) => {
        setUpdated(true);
        setError(false);
        updateDataSet('project', { ...res.data.data });
      })
      .catch(() => {
        setUpdated(false);
        setError(true);
      });

    setLoading(false);
  };

  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-6">Project More Info PDF</h3>
      <form onSubmit={handleSubmit(pdfHandler)}>
        <div className="card-lg">
          <div className="lg:flex lg:space-x-8">
            <div className="w-full">
              {dataSet.project.moreInfo && (
                <a
                  className="btn btn-gray text-center mb-4"
                  href={dataSet.project.moreInfo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See Current PDF
                </a>
              )}

              <input
                type="file"
                accept="application/pdf"
                className="my-2 w-full"
                name="moreInfo"
                ref={register()}
              />
              {errors.moreInfo && <p className="error">Please enter pdf</p>}
            </div>
          </div>

          <button className="btn block mt-8 mx-auto" type="submit">
            {loading ? <BtnSpinner /> : 'Update More Info'}
          </button>
          {updated && <p className="admin-info">More Info updated successfully</p>}
          {error && <p className="admin-error">Error updating More Info</p>}
        </div>
      </form>
    </Fragment>
  );
};

export default ProjectMoreInfo;
