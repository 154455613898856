import React, { Fragment, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line
import * as actions from '../../store/actions';

const Signup = (props) => {
  const dispatch = useDispatch();
  const onSignup = (payload) => dispatch(actions.authInit(payload, 'signup'));

  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');

  const changeModalHandler = () => {
    dispatch(actions.modalClose({ modalType: 'signup' }));
    dispatch(actions.modalOpen({ modalType: 'signin', modalData: { forceOpen: true } }));
  };

  return (
    <Fragment>
      <h1 className="mb-2 text-gray-700">Sign Up</h1>
      <p className="text-gray-700 mb-6">
        Alredy have an account?{' '}
        <button className="font-bold text-orange-500" onClick={changeModalHandler}>
          Sign In instead
        </button>
      </p>

      <form onSubmit={handleSubmit(onSignup)}>
        <div className="cols xl:space-x-4">
          <div className="xl:w-1/2 w-full text-center">
            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="name"
              ref={register({ required: true })}
              placeholder="Name"
            />
            {errors.name && <p className="error">Please enter your name</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="email"
              ref={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
              placeholder="Email Address"
            />
            {errors.email && <p className="error">Please enter a valid email address</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="password"
              name="password"
              ref={register({
                required: true,
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
              })}
              placeholder="Password"
            />
            {errors.password && (
              <p className="error">Minimum 8 characters. One uppercase, one special, one number</p>
            )}

            <input
              className="mt-4 mx-auto w-full"
              type="password"
              name="passwordConfirm"
              ref={register({ required: true, validate: (value) => value === password.current })}
              placeholder="Password"
            />
            {errors.passwordConfirm && <p className="error">Passwords do not match</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="company"
              ref={register({ required: true })}
              placeholder="Company Name"
            />
            {errors.company && <p className="error">Please enter your company name</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="position"
              ref={register()}
              placeholder="Position (Optional)"
            />
            {errors.position && <p className="error">Please enter your position</p>}
          </div>
          <div className="xl:w-1/2 w-full text-center">
            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="country"
              ref={register({ required: true })}
              placeholder="Country"
            />
            {errors.country && <p className="error">Please enter your country</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="state"
              ref={register()}
              placeholder="State (Optional)"
            />
            {errors.state && <p className="error">Please enter your state</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="city"
              ref={register({ required: true })}
              placeholder="City"
            />
            {errors.city && <p className="error">Please enter your city</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="address1"
              ref={register({ required: true })}
              placeholder="Address Line 1"
            />
            {errors.address1 && <p className="error">Please enter your address</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="address2"
              ref={register()}
              placeholder="Address Line 2 (Optional)"
            />
            {errors.address2 && <p className="error">Please enter your address</p>}

            <input
              className="mt-4 mx-auto w-full"
              type="text"
              name="zip"
              ref={register({ required: true })}
              placeholder="Zip Code"
            />
            {errors.zip && <p className="error">Please enter your zip code</p>}
          </div>
        </div>
        <button className="btn block mt-8 mx-auto text-xl px-16" type="submit">
          Sign Up
        </button>
      </form>
      <p className="text-gray-700 mt-16">
        See available projects{' '}
        <a
          className="font-bold text-teal-500"
          href="/projects"
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>{' '}
        For any questions, contact us at{' '}
        <a href="mailto:business@standfortrees.org">business@standfortrees.org</a>.
      </p>
    </Fragment>
  );
};

export default Signup;
