import * as actypes from '../actypes';
import axios from '../../api/axios';
import { authLogout } from './auth';
import { resultsSet, resultsReset } from './results';
import { badgesReset } from './badges';

export const formsReset = () => {
  return {
    type: actypes.FORMS_RESET,
  };
};

export const formInit = (newEl, form, cat) => {
  return {
    type: actypes.FORMS_INIT,
    newEl,
    form,
    cat,
  };
};

export const formSetOption = (elId, formId, value, cat) => {
  return {
    type: actypes.FORMS_SET_OPTION,
    elId,
    formId,
    value,
    cat,
  };
};

export const formDelete = (elId, formId, cat) => {
  return {
    type: actypes.FORMS_DELETE,
    elId,
    formId,
    cat,
  };
};

export const formCalculate = () => {
  return (dispatch, getState) => {
    dispatch(resultsReset());
    dispatch(badgesReset());
    const forms = getState().forms;
    console.log(JSON.stringify(forms));
    if (localStorage.getItem('token')) {
      axios
        .post('forms', forms)
        .then((res) => {
          dispatch(resultsSet(res.data.data));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch(authLogout());
          } else console.error(err);
        });
    } else {
      dispatch(authLogout());
    }
  };
};
