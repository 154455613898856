import * as actypes from '../actypes'
import axios from '../../api/axios'
import { authLogout } from './auth'

export const extrasInit = () => {
  return (dispatch, getState) => {
    if (localStorage.getItem('token')) {
      axios.get(`/extras`)
        .then(res => {
          dispatch(extrasSet({ extras: res.data.data }))
        })
        .catch(err => {
          console.error(err)
        })
    }
    else
      dispatch(authLogout())
  }
}

export const extrasReset = () => {
  return {
    type: actypes.EXTRAS_RESET
  }
}

export const extrasSet = (payload) => {
  return {
    type: actypes.EXTRAS_SET,
    payload
  }
}


