import React, { Fragment } from 'react';

const DateRange = ({ dateRangeCategories, dateRange, setDateRange, filterByDateRange }) => (
  <div className="lg:flex items-center w-full mb-4 max-w-3xl lg:space-x-2">
    <h5 className="text-teal-500 text-left">Date Range</h5>

    {dateRange && dateRangeCategories && (
      <Fragment>
        <div className="w-48">
          <div className="relative w-full mx-auto">
            <select
              className="block appearance-none w-full bg-gray-200 text-gray-700 py-3 px-2 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => setDateRange({ ...dateRange, type: e.target.value })}
              value={dateRange.type || 'none'}
            >
              <option key="none" value="none">
                none
              </option>
              {dateRangeCategories.map((f) => (
                <option value={f} key={f}>
                  {f}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-48 flex">
          <input
            className="ml-2"
            type="date"
            onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
            value={dateRange.start || ''}
          />
          <input
            className="ml-2"
            type="date"
            onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
            value={dateRange.end || ''}
          />
          <button className="ml-4 btn btn-teal" onClick={filterByDateRange}>
            Filter
          </button>
        </div>
      </Fragment>
    )}
  </div>
);

export default DateRange;
