import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from '../../../api/axios';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';
import { isEmpty } from 'lodash';
import { getDataApi } from '../../../utils/api';

const ManualAdd = () => {
  const { register, handleSubmit, errors } = useForm();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [dataSet, setDataSet] = useState({});
  const [projectsIndex, setProjectsIndex] = useState(0);

  const getSetData = async (query) => {
    const projects = await getDataApi(`admin/projects`);
    const users = await getDataApi(`admin/users`, { sort: 'name' });
    const badges = await getDataApi(`admin/badges`, {
      'slug[ne]': 'report-only',
      sort: 'app',
    });
    setDataSet({ projects, users, badges });
  };

  useEffect(() => {
    if (isEmpty(dataSet)) getSetData();
    // eslint-disable-next-line
  }, []);

  const submitHandler = async (data) => {
    setLoading(true);
    data.orderType = dataSet.badges.find((b) => b._id === data.badgeId).app;
    data.payed = true;
    data.manual = true;
    axios
      .post(`/admin/manual`, data)
      .then(() => {
        setUpdated(true);
        setError(false);
      })
      .catch(() => {
        setUpdated(false);
        setError(true);
      });

    setLoading(false);
  };

  const projectSelectors = [];

  if (!isEmpty(dataSet)) {
    for (let i = 0; i <= projectsIndex; i++) {
      projectSelectors.push(
        <div className="flex align-center justify-center space-x-4">
          <div className="relative w-full styled-select my-2">
            <select
              className="focus:outline-none focus:bg-white focus:border-gray-500"
              name={`projects[${i}]`}
              ref={register()}
            >
              {dataSet.projects.map((project) => (
                <option key={`${i}_${project._id}`} value={project._id}>
                  {project.name}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div
            className="btn w-fit btn-danger w-1/4 my-2"
            onClick={() => setProjectsIndex(projectsIndex - 1 >= 0 ? projectsIndex - 1 : 0)}
          >
            Remove Project
          </div>
        </div>,
      );
    }
  }

  return (
    <div className="flex flex-wrap lg:flex-no-wrap mx-auto shadow-md rounded py-4 px-8 lg:space-x-8 mb-6">
      <form className="w-full" onSubmit={handleSubmit(submitHandler)}>
        <h5 className="text-teal-500">User Related to Badge</h5>
        <div className="relative w-full styled-select my-2">
          <select
            className="focus:outline-none focus:bg-white focus:border-gray-500"
            name="userId"
            ref={register()}
          >
            {!isEmpty(dataSet) &&
              dataSet.users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name} - {user.company}
                </option>
              ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <h5 className="text-teal-500">Badge Earned</h5>
        <div className="relative w-full styled-select my-2">
          <select
            className="focus:outline-none focus:bg-white focus:border-gray-500"
            name="badgeId"
            ref={register()}
          >
            {!isEmpty(dataSet) &&
              dataSet.badges.map((badge) => (
                <option key={badge._id} value={badge._id}>
                  {badge.app === 'bc' ? 'Calculate & Offset' : 'Supporter Program'} - {badge.name}
                </option>
              ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <h5 className="text-teal-500">Currency</h5>
        <div className="relative w-full styled-select my-2">
          <select
            className="focus:outline-none focus:bg-white focus:border-gray-500"
            name="currency"
            ref={register()}
          >
            <option value="USD">United States Dollar</option>
            <option value="GBP">Great British Pound</option>
            <option value="EUR">Euro</option>
            <option value="COP">Colombian Peso</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <h5 className="text-teal-500">Payment Amount</h5>
        <input
          type="number"
          className="my-2 w-full"
          name="price"
          ref={register({ required: true })}
        />
        {errors.price && <p className="error">Please enter price</p>}

        <h5 className="text-teal-500">Offset Tonnes</h5>
        <input
          type="number"
          className="my-2 w-full"
          name="tonnes"
          ref={register({ required: true })}
        />
        {errors.tonnes && <p className="error">Please enter tonnes</p>}

        <h5 className="text-teal-500">Projects</h5>
        {projectSelectors}

        <div
          className="btn btn-teal btn-sm w-fit"
          onClick={() => setProjectsIndex(projectsIndex + 1)}
        >
          Add Project
        </div>

        <button className="btn block mt-8 mx-auto" type="submit">
          {loading ? <BtnSpinner /> : 'Save Details'}
        </button>
        {updated && <p className="admin-info">Manual badge issued successfully</p>}
        {error && <p className="admin-error">Error issuing badge</p>}
      </form>
    </div>
  );
};

export default ManualAdd;
