import React, { Fragment } from 'react';
import Footer from '../Footer/Footer';
import Modal from '../../components/Modal/Modal';
import * as actions from '../../store/actions';
// import Widget from '../../components/Donor/Widget';
import Header from './Header';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from '../../layout/Spinner/Spinner';

const Home = React.memo((props) => {
  const clickHandler = (app) => {
    window.dataLayer.push({
      program_name: app === 'dp' ? 'Supporter Program' : 'Calculate & Offset Program',
    });
    dispatch(actions.initPage(app));
  };

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  let activeElement = <Spinner />;

  if (!auth.token) {
    activeElement = (
      <div>
        <Modal />
      </div>
    );
    dispatch(actions.modalOpen({ modalType: 'signin', modalData: { forceOpen: true } }));
  }
  if (auth.token) {
    dispatch(actions.modalClose({ modalType: 'signin' }));
    dispatch(actions.modalClose({ modalType: 'signup' }));
    activeElement = (
      <Fragment>
        {/* Header Menu */}
        <Header {...props} />

        {/* Hero Image */}
        <div
          className={`w-full pb-24 pt-20 bg-cover ${
            !auth.token ? 'bg-bottom' : 'bg-center'
          } z-50 flex flex-col justify-center`}
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,78, 89,0.7), rgba(0,78, 89,0.7)),
            url('${require('../../assets/img/jungle.jpg')}')`,
            height: '82vh',
          }}
        >
          <h1 className="text-center lg:text-6xl lg:-mt-4 text-white">Business Programs</h1>
          {!auth.token && (
            <div className="flex justify-center mt-8 flex-wrap lg:flex-no-wrap px-4">
              <button
                className="btn btn-white text-3xl px-10 py-6"
                style={{ width: '400px' }}
                onClick={() => dispatch(actions.modalOpen({ modalType: 'signin' }))}
              >
                Log In
              </button>
              <button
                className="btn btn-white mt-4 lg:ml-4 lg:mt-0 text-3xl px-10 py-6"
                style={{ width: '400px' }}
                onClick={() => dispatch(actions.modalOpen({ modalType: 'signup' }))}
              >
                Sign Up
              </button>
            </div>
          )}

          {auth.token && (
            <div className="flex justify-center mt-8 flex-wrap lg:flex-no-wrap px-4">
              <button
                className="btn btn-white text-3xl px-10 py-6"
                style={{ width: '400px' }}
                onClick={() => clickHandler('bc')}
              >
                Calculate &amp; Offset Program
              </button>
              <button
                className="btn btn-white mt-4 lg:ml-4 lg:mt-0 text-3xl px-10 py-6"
                style={{ width: '400px' }}
                onClick={() => clickHandler('dp')}
              >
                Supporter Program
              </button>
            </div>
          )}
        </div>

        {/* Content */}
        {/* {!auth.token && (
        <Fragment>
          <div className="text-justify text-xl  text-gray-700 z-10">
            <div className="container px-6 py-12">
              <h2 className="text-xl lg:text-3xl text-center mb-12">
                Background information - Calculate & Offset program
              </h2>
              <p className="mb-4">
                The Calculate & Offset Program requires data on emissions and activities covering a
                minimum of:
              </p>
              <ul className="ml-16 mb-4 list-disc">
                <li>a 12-month period in the last 18 months (the more recent, the better)</li>
                <li>at least Scopes 1 and 2</li>
              </ul>
              <p className="mb-4">
                Please download{' '}
                <a
                  href="https://sftcdn.sfo2.cdn.digitaloceanspaces.com/badging-program/misc/content-reporting.pdf"
                  target="_blank "
                  rel="noopener noreferrer"
                >
                  this worksheet
                </a>{' '}
                to organize information before using the calculator interface.
              </p>
              <p>
                Alternative options, based on floorspace or spend, are available for certain items
                for which real data may be unknown.
              </p>
              <p>
                You will receive an automatic discount on our standard price of $15 / tonne based on
                your order size. See volume discounts{' '}
                <a
                  href="https://sftcdn.sfo2.cdn.digitaloceanspaces.com/badging-program/misc/volume-discounts.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </p>
              <button type="button" className="btn btn-orange mx-auto my-8 text-2xl py-4 px-10 ">
                Get Started
              </button>

              <h2 className="text-xl lg:text-3xl text-center my-8">
                Background information - Supporter program
              </h2>
              <p className="mb-4">
                Use the widget below to simulate your badge level and the emissions reductions your
                support will generate.
              </p>
              <p className="mb-4">
                Input your employee count, annual revenue, and intended contribution. You can also
                select to forego the volume discount. This has no implications for the badge you
                receive, but increases your impact by leaving more credits available for future
                sales.
              </p>
              <div className="mt-4  mx-auto z-50 py-6 px-12 rounded bg-teal-500 text-white">
                <Widget />
              </div>
              <button type="button" className="btn btn-orange mx-auto my-8 text-2xl py-4 px-10 ">
                Get Started
              </button>

              <p className="mb-4">
                The two tables below show how we determine your company size as a function of the
                number of employees and turnover, and based on this, the badge level.
              </p>
              <table className="table-auto mx-auto mb-4 w-full border-orange-500 rounded text-gray-700 my-6 text-center">
                <thead>
                  <tr>
                    <th className="p-2 bg-gray-200 text-gray-700">Company Size</th>
                    <th className="p-2 bg-gray-200 text-gray-700">Employees</th>
                    <th className="p-2 bg-gray-200 text-gray-700">US$ Annual Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-gray-400 p-2 capitalize">Small</td>
                    <td className="border-b border-gray-400 p-2">1-49</td>
                    <td className="border-b border-gray-400 p-2">0 - 10 million</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-400 p-2 capitalize">Medium</td>
                    <td className="border-b border-gray-400 p-2">50-249</td>
                    <td className="border-b border-gray-400 p-2">&gt; 10 – 100 million</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-400 p-2 capitalize">Large</td>
                    <td className="border-b border-gray-400 p-2">&gt;= 250 </td>
                    <td className="border-b border-gray-400 p-2">&gt;100 million</td>
                  </tr>
                </tbody>
              </table>

              <p className="mb-4">
                If companies fall between two levels, the higher applies. For example, companies
                with 40 employees and annual revenues of $15 million will be <strong>medium</strong>
                .
              </p>

              <table className="table-auto mx-auto mb-4 w-full border-orange-500 rounded text-gray-700 my-6 text-center">
                <thead>
                  <tr>
                    <th className="p-2 bg-gray-200 text-gray-700">Business Size</th>
                    <th className="p-2 bg-gray-200 text-gray-700">Bronze Supporter</th>
                    <th className="p-2 bg-gray-200 text-gray-700">Silver Supporter</th>
                    <th className="p-2 bg-gray-200 text-gray-700">Gold Supporter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-gray-400 p-2 capitalize">Small</td>
                    <td className="border-b border-gray-400 p-2">$5,000-$15,000</td>
                    <td className="border-b border-gray-400 p-2">$15,001-$35,000</td>
                    <td className="border-b border-gray-400 p-2">$35,001-$60,000</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-400 p-2 capitalize">Medium</td>
                    <td className="border-b border-gray-400 p-2">$10,000-$20,000</td>
                    <td className="border-b border-gray-400 p-2">$20,001-$42,500</td>
                    <td className="border-b border-gray-400 p-2">$42,501-$60,000</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-400 p-2 capitalize">Large</td>
                    <td className="border-b border-gray-400 p-2">$15,000-$25,000</td>
                    <td className="border-b border-gray-400 p-2">$25,001-$50,000</td>
                    <td className="border-b border-gray-400 p-2">$50,001-$60,000</td>
                  </tr>
                </tbody>
              </table>

              <h2 className="text-xl lg:text-3xl text-center my-12">
                Background information – Content & reporting
              </h2>
              <p className="mb-4">
                Content and reporting packages are based the number of tonnes (Calculate & Offset
                program) or the order value (Supporter program) allocated to a project. This means
                that if you choose to split your contribution between more than one project, the
                number of tonnes allocated to each project determine your package for that project.
              </p>
              <p className="mb-4">
                For orders of less than 125 tonnes, we invite you to use standard content from Stand
                For Trees’ social media.
              </p>
              <p className="mb-4">
                For each project in an order, you will receive a Stand For Trees PDF certificate
                with the purchase date and number of tonnes retired, plus a web link to the
                retirement record in the IHS-Markit environmental registry confirming that the
                credits have been retired on your behalf.
              </p>
              <button type="button" className="btn btn-orange mx-auto my-8 text-2xl py-4 px-10 ">
                View content & reporting packages
              </button>

              <p className="mb-4">
                For any questions, contact us at{' '}
                <a href="mailto:business@standfortrees.org">business@standfortrees.org</a>.
              </p>
            </div>
          </div>
        </Fragment>
      )} */}
        <Footer />
        <Modal />
      </Fragment>
    );
  }

  return activeElement;
});

export default Home;
