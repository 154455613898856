import React, { Fragment, useState } from 'react';
import BtnSpinner from '../../../../layout/BtnSpinner/BtnSpinner';

const OrderOptions = ({
  data,
  updateDataSet,
  updateDataHandler,
  getDataApi,
  downloadCSVHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState('');
  const [error, setError] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [email, setEmail] = useState('reservation');

  const setUpdateDataHandler = async (endpoint, id) => {
    setLoading('info');
    const res = await updateDataHandler(endpoint, 'order', id);
    setLoading(false);

    if (res) setUpdated('info');
    else setError('info');
  };

  const getDataApiHandler = async (endpoint, id, option) => {
    setLoading(option);
    const res = await getDataApi(endpoint + id);
    setLoading('');

    if (res) {
      setError('');
      setUpdated(option);
    } else {
      setUpdated('');
      setError(option);
    }
  };

  const completeOrderHandler = () => {
    if (
      window.confirm(
        'Are you sure you want to complete order forcefully? This will override contract & payment and activate the badge.',
      )
    )
      getDataApiHandler('/admin/orders/finalize/', data.order._id, 'complete');
  };

  const paymentLinkHandler = async () => {
    setLoading('payment');
    const link = await getDataApi('/admin/orders/payment/' + data.order._id);
    setLoading('');

    if (link) {
      setError('');
      setUpdated('payment');
      setPaymentLink(link);
    } else {
      setUpdated('');
      setError('payment');
    }
  };

  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-6">Options</h3>
      <div className="flex flex-wrap lg:flex-no-wrap mx-auto card-lg lg:space-x-8 mb-6">
        {/* details */}
        <div className="admin-details w-full lg:w-1/3 p-4 text-center">
          <h3 className="mb-4 text-gray-700">Change Details</h3>
          <h5>Status</h5>
          <div className="relative w-64 styled-select mx-auto my-4">
            <select
              className="focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => updateDataSet('order', { status: e.target.value })}
              value={data.order.status}
            >
              <option value="active">active</option>
              <option value="expired">expired</option>
              <option value="revoked">revoked</option>
              <option value="contract">contract</option>
              <option value="payment">payment</option>
              <option value="pending">pending</option>
              <option value="pastDue">past due</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          <h5>Reporting Level</h5>
          <div className="relative w-64 styled-select mx-auto my-4">
            <select
              className="focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => updateDataSet('order', { level: e.target.value })}
              value={data.order.level}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          <h5>Badge Achieved</h5>
          <div className="relative w-64 styled-select mx-auto my-4">
            <select
              className="focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => updateDataSet('order', { badgeId: e.target.value })}
              value={data.order.badgeId}
            >
              {data.badges.map((b) => (
                <option key={b._id} value={b._id}>
                  {b.name}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          <button
            className={`btn btn-teal mx-auto mb-6 w-48 mt-6`}
            disabled={loading === 'info'}
            onClick={() => setUpdateDataHandler('orders', data.order._id)}
          >
            {loading === 'info' ? <BtnSpinner /> : 'Save Details'}
          </button>
          {updated === 'info' && <p className="admin-info">Order updated Ssuccessfully</p>}
          {error === 'info' && <p className="admin-error">Error updating attachments</p>}
        </div>

        {/* emails */}
        <div className="admin-details w-full lg:w-1/3 p-4 lg:border-l text-center">
          <h3 className="mb-4 text-gray-700">Emails</h3>
          <div className="relative w-64 styled-select mx-auto">
            <select
              className="focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            >
              <option value="reservation">Reservation</option>
              <option value="payment">Checkout Link</option>
              <option value="order">Order Completed</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <button
            className="btn btn-teal mx-auto mb-6 mt-4"
            onClick={() => getDataApiHandler(`/admin/orders/email/${email}/`, data.order._id)}
          >
            Send Email
          </button>
          {updated === 'email' && <p className="admin-info">Email sent successfully</p>}
          {error === 'email' && <p className="admin-error">Error sending email</p>}
        </div>

        <div className="admin-details w-full lg:w-1/3 p-4 lg:border-l text-center">
          <h3 className="mb-4 text-gray-700">Options</h3>

          <button className="btn mx-auto mb-6" onClick={downloadCSVHandler}>
            Download CSV
          </button>

          <button className="btn btn-danger mx-auto mb-6" onClick={completeOrderHandler}>
            Force Complete Order
          </button>
          {updated === 'complete' && (
            <p className="admin-info">Order completed &amp; emails sent</p>
          )}
          {error === 'complete' && <p className="admin-error">Error completing order.</p>}

          <button className="btn btn-white mx-auto mb-6" onClick={paymentLinkHandler}>
            Generate Payment Link
          </button>
          {updated === 'payment' && (
            <p className="admin-info">Payment link generated, copy below</p>
          )}
          {error === 'payment' && (
            <p className="admin-error">
              Error generating payment link. Make sure contract is signed first.
            </p>
          )}
          {paymentLink && (
            <p className="break-all text-sm text-gray-600 mx-8 shadow p-2 rounded bg-gray-100">
              {paymentLink}
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OrderOptions;
