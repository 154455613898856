const appPages = {
  home: { name: 'Homepage' },
  bc: {
    instructions: { id: 'instructions', name: 'Instructions', next: 'fuel' },
    fuel: {
      id: 'fuel',
      name: 'Scope 1: Direct Fuels & Refrigerants',
      next: 'electricity',
    },
    electricity: {
      id: 'electricity',
      name: 'Scope 2: Electricity Use',
      next: 'travel',
      skip: 'results',
    },
    travel: { id: 'travel', name: 'Scope 3: Business Travel', next: 'commute' },
    commute: {
      id: 'commute',
      name: 'Scope 3: Commuting',
      next: 'goods',
      skip: 'results',
    },
    goods: {
      id: 'goods',
      name: 'Scope 3: Materials & Products In Supply Chain',
      next: 'waste',
    },
    waste: { id: 'waste', name: 'Scope 3: Water & Waste', next: 'transport' },
    transport: {
      id: 'transport',
      name: 'Scope 3: Transport & Distribution',
      next: 'storage',
    },
    storage: {
      id: 'storage',
      name: 'Scope 3: Third Party Storage',
      next: 'optional',
    },
    optional: {
      id: 'optional',
      name: 'Optional: Business Size',
      next: 'results',
    },
    results: { id: 'results', name: 'Report Summary', next: 'badges' },
    badges: { id: 'badges', name: 'Package / Report Selection', next: 'projects' },
    projects: {
      id: 'projects',
      name: 'Project Selection',
      next: 'reservation',
      skip: 'extras',
      invalid: true,
    },
    reservation: {
      id: 'reservation',
      name: 'Quote, Invoice, Order & Reservation choices',
      next: 'thanks',
      invalid: true,
    },

    extras: {
      id: 'extras',
      name: 'Content Package Extras',
      next: 'reservation',
    },
    checkout: { id: 'checkout', name: 'Checkout' },
  },
  dp: {
    donor: {
      id: 'donor',
      name: 'Supporter Program',
      next: 'projects',
      invalid: true,
    },
    projects: {
      id: 'projects',
      name: 'Project Selection',
      next: 'reservation',
    },
    extras: {
      id: 'extras',
      name: 'Content & Reporting Package Extras',
      next: 'reservation',
    },
    reservation: {
      id: 'reservation',
      name: 'Quote, Invoice, Order & Reservation choices',
      next: 'thanks',
      invalid: true,
    },

    checkout: { id: 'checkout', name: 'Checkout' },
  },
};

export default appPages;
