import React from 'react';

const Filters = ({ categories, filters, filterBy, filterValue, setFilter }) => (
  <div className="lg:flex items-center w-full mb-4 max-w-3xl lg:space-x-2">
    <h5 className="text-teal-500 text-left">Filter By</h5>
    <div className="w-48">
      <div className="relative w-full mx-auto">
        <select
          className="block appearance-none w-full bg-gray-200 text-gray-700 py-3 px-2 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          onChange={(e) => setFilter(e.target.value)}
          value={filterBy}
        >
          <option value="">none</option>
          {categories.map((c) => (
            <option value={c} key={c}>
              {c}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>

    {filters && filterBy && (
      <div className="w-48">
        <div className="relative w-full mx-auto">
          <select
            className="block appearance-none w-full bg-gray-200 text-gray-700 py-3 px-2 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={(e) => setFilter(filterBy, e.target.value)}
            value={filterValue || ''}
          >
            <option key="none" value="">
              none
            </option>
            {filters[filterBy].map((f) => (
              <option value={f}>{f}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default Filters;
