

import * as actions from '../actypes'
import appPages from '../../constants/pages'
import { updateObject } from '../helpers'

const initState = {
  valid: [],
  history: []
}


const initPage = (state, { app }) => {
  const current = app === 'bc' ? appPages[app]['instructions'] : appPages[app]['donor']
  const updHistory = { ...state }.history
  updHistory.push('home')
  return updateObject(state, { current, app, history: updHistory })
}

const prevPage = state => {
  const updHistory = { ...state }.history
  const prev = updHistory.pop()
  if (prev === 'home')
    return initState
  else {
    const current = appPages[state.app][prev]
    return updateObject(state, { current, history: updHistory })
  }
}

const nextPage = (state, { method }) => {
  const next = state.current[method]

  if (next === 'home')
    return initState
  else {
    const updHistory = { ...state }.history
    updHistory.push(state.current.id)

    const current = appPages[state.app][next]
    return updateObject(state, { current, history: updHistory })
  }
}

const jumpPage = (state, { page }) => {
  if (page === 'home')
    return initState
  if (page === 'thanks')
    return { app: 'thanks' }
  else {
    const updHistory = { ...state }.history
    updHistory.push(state.current.id)

    const current = appPages[state.app][page]
    return updateObject(state, { current, history: updHistory })
  }
}


const validPage = (state, { status }) => {
  const updValid = { ...state }.valid

  if (updValid.includes(state.current.id) && status === false) {
    const index = updValid.indexOf(state.current.id)
    if (index > -1)
      updValid.splice(index, 1);

    return updateObject(state, { valid: updValid })
  }

  if (!updValid.includes(state.current.id) && status === true) {
    updValid.push(state.current.id)
    return updateObject(state, { valid: updValid })
  }
  return state
}


const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.PAGES_RESET: return initState
    case actions.PAGES_INIT: return initPage(state, action)
    case actions.PAGES_PREV: return prevPage(state, action)
    case actions.PAGES_NEXT: return nextPage(state, action)
    case actions.PAGES_JUMP: return jumpPage(state, action)
    case actions.PAGES_VALID: return validPage(state, action)
    default: return state
  }
}


export default reducer