import * as actypes from '../actypes'

export const modalOpen = (data) => {
  return {
    type: actypes.MODAL_OPEN,
    payload: data
  }
}

export const modalClose = () => {
  return {
    type: actypes.MODAL_CLOSE,
  }
}