import React from 'react'

const FetchError = ({ error, message, clicked, button }) => (
  <div className="text-center text-gray-500 mt-16">
    <h3>{error}</h3>
    <h3>{message}</h3>
    {clicked ? <button className="mt-4 mx-auto btn btn-teal" onClick={clicked}>{button ? button : 'Click Here'}</button> : null}
  </div>
)

export default FetchError