import React, { useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import axios from '../../../api/axios';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';

const ProjectReports = ({ dataSet, updateDataSet }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const submitHandler = async () => {
    setLoading(true);

    axios
      .patch(`/admin/projects/${dataSet.project._id}`, dataSet.project)
      .then((res) => {
        setUpdated(true);
        setError(false);
        updateDataSet('project', { ...res.data.data });
      })
      .catch(() => {
        setUpdated(false);
        setError(true);
      });

    setLoading(false);
  };

  const updReportsHandler = (field, value, index) => {
    const updData = [...dataSet.project.reports];

    updData[index][field] = value;

    updateDataSet('project', { reports: updData });
  };

  const removeReportsHandler = (index) => {
    const updData = [...dataSet.project.reports];

    updData.splice(index, 1);

    updateDataSet('project', { reports: updData });
  };

  const addReportsHandler = () => {
    const updData = [...dataSet.project.reports];

    updData.push({ level: 1, link: '' });

    updateDataSet('project', { reports: updData });
  };

  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-6">Project Reports</h3>
      <div className="card-lg">
        <div className="lg:flex flex-wrap">
          {dataSet.project.reports &&
            !isEmpty(dataSet.project.reports) &&
            dataSet.project.reports.map((a, i) => (
              <div className="my-4 lg:mr-2" key={`Attachment_${i}`}>
                <h5>Link</h5>
                <input
                  type="text"
                  className="w-full"
                  onChange={(e) => updReportsHandler('link', e.target.value, i)}
                  value={a.link || ''}
                />

                <h5>Year Reported</h5>
                <input
                  type="number"
                  className="w-full"
                  onChange={(e) => updReportsHandler('year', e.target.value, i)}
                  value={a.year || ''}
                />

                <button
                  className="btn btn-sm btn-gray mt-4"
                  onClick={() => removeReportsHandler(i)}
                >
                  Remove Report
                </button>
              </div>
            ))}
        </div>
        <button className="btn btn-teal mt-4" onClick={addReportsHandler}>
          Add Report
        </button>
        <button className="btn block mt-8 mx-auto" onClick={submitHandler}>
          {loading === 'info' ? <BtnSpinner /> : 'Update Reports'}
        </button>
        {updated && <p className="admin-info">Proiect updated successfully</p>}
        {error && <p className="admin-error">Error updating project</p>}
      </div>
    </Fragment>
  );
};

export default ProjectReports;
