import React, { Fragment } from 'react';

const OrderInfo = ({ data }) => {
  let videoProjectNames = [];

  if (data.order.videos)
    videoProjectNames = data.order.videos.map((projectId) => {
      // eslint-disable-next-line
      const project = data.projects.find((p) => p._id == projectId);

      if (project) return project.name;

      return null;
    });

  return (
    <div className="card-lg mb-6 p-12">
      <div className="admin-details lg:flex lg:space-x-8 justify-between">
        <div>
          <h5>Order Number</h5>
          <h3>
            <span>{data.order.number}</span>
          </h3>

          <h5>Order Type</h5>
          <h3>
            <span>
              {data.order.orderType === 'bc'
                ? 'Calculate & Offset'
                : data.order.orderType === 'dp'
                ? 'Supporter Program'
                : 'Report Only'}
            </span>
          </h3>

          <h5>Order Status</h5>
          <h3>
            <span>{data.order.status}</span>
          </h3>
        </div>
        <div>
          <h5>Company</h5>
          <h3>
            <span>{data.user.company}</span>
          </h3>

          <h5>User</h5>
          <h3>
            <span>{data.user.name}</span>
          </h3>

          <h5>Email</h5>
          <h3>
            <span>{data.user.email}</span>
          </h3>
        </div>
        <div>
          <h5>Tonnes</h5>
          <h3>
            <span>{data.order.tonnes}</span>
          </h3>

          <h5>Price</h5>
          <h3>
            <span>{data.order.price}</span>
          </h3>

          <h5>Currency</h5>
          <h3>
            <span>{data.order.currency}</span>
          </h3>
        </div>
        <div>
          <h5>Projects</h5>
          <h3>
            <span>{data.projects.map((p) => p.name + '\n')}</span>
          </h3>

          {videoProjectNames.length > 0 && (
            <Fragment>
              <h5>Videos Requested</h5>
              <h3>
                <span>{videoProjectNames.map((name) => name + '\n')}</span>
              </h3>
            </Fragment>
          )}
          <h5>Created Date</h5>
          <h3>
            <span>{data.order.createdAt ? data.order.createdAt.substring(0, 10) : 'N/A'}</span>
          </h3>

          <h5>Issued Date</h5>
          <h3>
            <span>{data.order.issuedAt ? data.order.issuedAt.substring(0, 10) : 'N/A'}</span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
