import React, { Fragment, useRef } from 'react';

const UserPass = ({ id, submitNewPasswordHandler, loading, error, updated }) => {
  const pass = useRef('');
  return (
    <Fragment>
      <h3 className="text-gray-700 text-center text-3xl my-2">Update User Password</h3>
      <div className="shadow-md rounded pt-4 pb-8 px-8">
        <input
          className="my-6 mx-auto w-64"
          type="text"
          name="password"
          placeholder="New Password"
          ref={pass}
        />

        <button
          className="btn btn-danger mx-auto"
          disabled={loading === 'newpassword'}
          onClick={() => submitNewPasswordHandler(pass.current.value, id)}
        >
          Change Password
        </button>
        {updated === 'newpassword' && (
          <p className="admin-info">Password reset link sent to user</p>
        )}
        {error === 'newpassword' && (
          <p className="admin-error">Error sending password reset link</p>
        )}
      </div>
    </Fragment>
  );
};

export default UserPass;
