import React, { Fragment, useState } from 'react';
import { isEmpty } from 'lodash';
import { getDataApi, updateDataApi } from '../../../../utils/api';
import csv from '../../../../utils/csv';
import Info from './Info';
import Options from './Options';
import Retirements from './Retirements';
import Reservation from './Reservation';
import Videos from './Videos';

const Order = (props) => {
  const [dataSet, setDataSet] = useState({});

  const getSetData = async (query) => {
    const order = await getDataApi(`${props.match.url}`, query);
    const user = await getDataApi(`/admin/users/${order.userId}`);
    const retirements = await getDataApi(`/admin/retirements?orderId=${order._id}`);
    const reservation = await getDataApi(`/admin/reservations?orderId=${order._id}`);
    const extras = await getDataApi(`/admin/extras?orderId=${order._id}`);
    const badges = await getDataApi(`/admin/badges`, { app: order.orderType });

    const projects = [];
    for (const project of order.projects) {
      projects.push(await getDataApi(`/admin/projects/${project}`));
    }

    setDataSet({ order, user, projects, retirements, extras, badges, reservation: reservation[0] });
  };

  const updateDataSet = (field, data) => {
    const updData = { ...dataSet };
    updData[field] = { ...dataSet[field], ...data };
    setDataSet(updData);
  };

  const updateDataHandler = async (endpoint, option, id) => {
    const res = await updateDataApi(`/admin/${endpoint}/${id}`, dataSet[option]);
    return res;
  };

  const downloadCSVHandler = async () => {
    const headers = [
      'tonnes',
      'price',
      'PPT',
      'currency',
      'projects',
      'extras',
      'type',
      'badge',
      'company',
      'user',
      'email',
      'status',
      'number',
      'reportLevel',
      'createdDate',
      'issuedDate',
    ];

    const data = [
      {
        tonnes: dataSet.order.tonnes,
        price: dataSet.order.price,
        PPT: (dataSet.order.price / dataSet.order.tonnes).toFixed(2),
        currency: dataSet.order.currency,
        projects: dataSet.projects.map((p) => p.name).join('/'),
        extras: dataSet.extras.map((e) => e.name).join('/'),
        type: dataSet.order.orderType,
        badge: dataSet.badges.find((b) => b._id === dataSet.order.badgeId).name,
        company: dataSet.user.company,
        user: dataSet.user.name,
        email: dataSet.user.email,
        status: dataSet.order.status,
        number: dataSet.order.number,
        reportLevel: dataSet.order.level,
        createdDate: dataSet.order.createdAt,
        issuedDate: dataSet.order.issuedAt || 'N/A',
      },
    ];
    return csv(data, headers, 'orders');
  };

  if (isEmpty(dataSet)) getSetData();

  return (
    <div>
      <h3 className="text-gray-700 text-center text-3xl my-2">Order Details</h3>
      {!isEmpty(dataSet) && (
        <Fragment>
          <Info
            data={dataSet}
            updateDataSet={updateDataSet}
            updateDataHandler={updateDataHandler}
            getDataApi={getDataApi}
            downloadCSVHandler={downloadCSVHandler}
          />
          <Options
            data={dataSet}
            updateDataSet={updateDataSet}
            updateDataHandler={updateDataHandler}
            getDataApi={getDataApi}
            downloadCSVHandler={downloadCSVHandler}
          />

          {dataSet.order.orderType !== 'ro' && (
            <Fragment>
              {dataSet.retirements && dataSet.retirements.length > 0 ? (
                <Retirements data={dataSet} getDataApi={getDataApi} />
              ) : null}

              <Reservation data={dataSet} updateDataSet={updateDataSet} />
            </Fragment>
          )}
          {dataSet.order.videos.length > 0 && (
            <Videos
              dataSet={dataSet}
              updateDataSet={updateDataSet}
              updateDataHandler={updateDataHandler}
              getDataApi={getDataApi}
              downloadCSVHandler={downloadCSVHandler}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};
export default Order;
