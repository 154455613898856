import * as actypes from '../actypes';
import axiosInstance from '../../api/axios';
import { authLogout } from './auth';

export const fetchOptions = (type) => {
  return (dispatch, getState) => {
    if (getState().options.error) dispatch(setOptions('error', false));

    const state = getState().options;

    if (!(type in state)) {
      axiosInstance
        .get(`/forms/${type}`)
        .then((res) => {
          dispatch(setOptions(type, res.data.data));
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            dispatch(authLogout());
          }
          dispatch(setOptions('error', true));
        });
    }
  };
};

const setOptions = (type, data) => {
  let payload = {};
  payload[type] = data;

  return {
    type: actypes.SET_OPTIONS,
    payload,
  };
};
