import React, { useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { getDataApi } from '../../../utils/api';
import ProjectInfo from './ProjectInfo';
import ProjectMoreInfo from './ProjectMoreInfo';
import ProjectImages from './ProjectImages';
import ProjectBundles from './ProjectBundles';
import ProjectReports from './ProjectReports';

const Project = (props) => {
  const [dataSet, setDataSet] = useState({});

  const getSetData = async (query) => {
    const project = await getDataApi(`${props.match.url}`, query);
    setDataSet({ project });
  };

  const updateDataSet = (field, data) => {
    const updData = { ...dataSet };
    updData[field] = { ...dataSet[field], ...data };
    setDataSet(updData);
  };

  if (isEmpty(dataSet)) getSetData();

  return (
    <div>
      <h3 className="text-gray-700 text-center text-3xl my-2">Project Details</h3>
      {!isEmpty(dataSet) && (
        <Fragment>
          <ProjectInfo dataSet={dataSet} updateDataSet={updateDataSet} />
          <ProjectMoreInfo dataSet={dataSet} updateDataSet={updateDataSet} />
          <ProjectImages dataSet={dataSet} updateDataSet={updateDataSet} />
          <ProjectBundles dataSet={dataSet} updateDataSet={updateDataSet} />
          <ProjectReports dataSet={dataSet} updateDataSet={updateDataSet} />
        </Fragment>
      )}
    </div>
  );
};
export default Project;
