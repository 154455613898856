import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ResizeDetector from 'react-resize-detector';
// import warningArray from '../../constants/warningArray';
function App(props) {
  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <div className="flex flex-col justify-between min-h-screen">
          <Header width={width} />
          {/* {warningArray.includes(props.currentComponent) && (
            <div className="bg-red-600 bg-opacity-75 text-white text-center py-1">
              <h3>
                Warning: Your input data is saved at the end of the process. To go back to the
                previous screen, use the orange button on the page.
              </h3>
            </div>
          )} */}
          <div
            className={`flex-grow ${!props.nocontainer ? 'container' : ''} ${
              !props.nopadding ? 'pb-16 pt-10 px-5' : ''
            }`}
          >
            {props.children}
          </div>
          <Footer />
        </div>
      )}
    />
  );
}

export default App;
