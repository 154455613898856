import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {
  extras: {},
  selected: {},
  available: false
}

const extrasSet = (state, action) => {
  const updState = { ...state, ...action.payload }
  return updateObject(state, updState)
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.EXTRAS_SET: return extrasSet(state, action)
    case actypes.EXTRAS_RESET: return initState
    default: return state
  }
}

export default reducer