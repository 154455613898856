import React, { Fragment } from 'react';
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner';

const UserInfo = ({
  data,
  updateDataSet,
  updateDataHandler,
  resetPasswordHandler,
  downloadCSVHandler,
  loading,
  updated,
  error,
  errors,
}) => {
  return (
    <div className="flex flex-wrap lg:flex-no-wrap mx-auto shadow-md rounded py-4 px-8 lg:space-x-8 mb-6">
      <div className="w-full lg:w-1/3 mt-6">
        <h2 className="text-gray-700 text-center text-2xl">{data.user.name}</h2>
        <h2 className="text-gray-600 text-center font-normal text-sm mb-8">{data.user.email}</h2>
        <h2 className="text-gray-700 text-center font-bold text-sm mb-2">Last Login:</h2>
        <h2 className="text-gray-600 font-normal text-center text-sm mb-8">{data.user.login}</h2>

        <button
          className="block mb-4 w-full mx-auto py-3 px-6 text-white bg-teal-500 rounded shadow"
          onClick={() => resetPasswordHandler(data.user.email)}
        >
          Send password reset email
        </button>
        {updated === 'password' && <p className="admin-info">Password reset link sent to user</p>}
        {error === 'password' && <p className="admin-error">Error sending password reset link</p>}
      </div>
      <div className="w-full lg:w-2/3 lg:border-l">
        <div className="flex flex-wrap w-full justify-between mb-8 mt-8">
          <div className="w-full lg:w-1/2 lg:pl-8 lg:pr-4">
            <h5 className="text-teal-500">Position</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="position"
              value={data.user.position}
              onChange={(e) => updateDataSet('user', { position: e.target.value })}
            />

            <h5 className="text-teal-500">Name</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="company"
              value={data.user.name}
              onChange={(e) => updateDataSet('user', { name: e.target.value })}
            />
            {errors.name && <p className="error">Please enter your name</p>}

            <h5 className="text-teal-500">Email</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="company"
              value={data.user.email}
              onChange={(e) => updateDataSet('user', { email: e.target.value })}
            />
            {errors.email && <p className="error">Please enter your email</p>}

            <h5 className="text-teal-500">Company</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="company"
              value={data.user.company}
              onChange={(e) => updateDataSet('user', { company: e.target.value })}
            />
            {errors.company && <p className="error">Please enter your company name</p>}

            <h5 className="text-teal-500">Status</h5>
            <div className="relative w-full styled-select my-2">
              <select
                className="focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e) => updateDataSet('user', { status: e.target.value })}
                value={data.user.status}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <h5 className="text-teal-500">Role</h5>
            <div className="relative w-full styled-select my-2">
              <select
                className="focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e) => updateDataSet('user', { role: e.target.value })}
                value={data.user.role}
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <h5 className="text-teal-500">Address Line 1</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="address1"
              value={data.user.address1}
              onChange={(e) => updateDataSet('user', { address1: e.target.value })}
            />
            {errors.address1 && <p className="error">Please enter your address</p>}

            <h5 className="text-teal-500">Address Line 2</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="address2"
              value={data.user.address2}
              onChange={(e) => updateDataSet('user', { address2: e.target.value })}
            />

            <h5 className="text-teal-500">City</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="city"
              value={data.user.city}
              onChange={(e) => updateDataSet('user', { city: e.target.value })}
            />
            {errors.city && <p className="error">Please enter your city</p>}
            {'state' in data && (
              <Fragment>
                <h5 className="text-teal-500">State</h5>
                <input
                  type="text"
                  name="state"
                  className="my-2 w-full"
                  value={data.user.state}
                  onChange={(e) => updateDataSet('user', { state: e.target.value })}
                />
              </Fragment>
            )}

            <h5 className="text-teal-500">Country</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="country"
              value={data.user.country}
              onChange={(e) => updateDataSet('user', { country: e.target.value })}
            />
            {errors.country && <p className="error">Please enter your country</p>}

            <h5 className="text-teal-500">Zip Code</h5>
            <input
              type="text"
              className="my-2 w-full"
              name="zip"
              value={data.user.zip}
              onChange={(e) => updateDataSet('user', { zip: e.target.value })}
            />
            {errors.zip && <p className="error">Please enter your zip code</p>}
          </div>
        </div>

        {updated === 'user' && <p className="admin-info">User updated successfully</p>}
        {error === 'user' && <p className="admin-error">Error updating user</p>}

        <button
          className="block mx-auto mb-6 mt-6 py-3 px-6 text-white btn-danger rounded shadow"
          disabled={loading === 'user'}
          onClick={() => updateDataHandler('users', 'user', data.user._id)}
        >
          {loading === 'user' ? <BtnSpinner /> : 'Update Info'}
        </button>
      </div>
    </div>
  );
};

export default UserInfo;
