import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import axios from '../../api/axios';
import _ from 'lodash';

import { loadStripe } from '@stripe/stripe-js';
import { PayPalButton } from 'react-paypal-button-v2';

import * as actions from '../../store/actions';

import App from '../../layout/App/App';
import Spinner from '../../layout/Spinner/Spinner';
import Modal from '../Modal/Modal';
import FetchError from '../Shared/FetchError';
import DetailsTable from '../Reservation/DetailsTable';

const stripeKey =
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? 'pk_test_9Ub9tbxLnekk5mzWEvvo63Ub'
    : 'pk_test_9Ub9tbxLnekk5mzWEvvo63Ub';
const stripePromise = loadStripe(stripeKey);

const payPalId =
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? 'AfOnO6h5Pqxl69ibRwbdw2I0jxZNx_hG_w5Po5t9zyLXWQW3tsiIpAWqM2bTYCFb1QTTI5lo5azxUURY'
    : 'AfI0eCfPPJlbp3vOlMf5tcObU9eK1XXeR6Qc5tZ_ftc-gexXj78Sx_OlcUMX9KZSxW37nCBEOA9xv79q';

const Checkout = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const token = qs.parse(props.location.search, { ignoreQueryPrefix: true }).t;

  const [userValid, setUserValid] = useState(false);
  const [order, setOrder] = useState({});

  let activeElement = <Spinner />;

  const stripeHandler = async () => {
    const stripe = await stripePromise;

    const bpb = await axios.post('/stripe', order);

    if (bpb.data.status === 'error') return console.error('bpb error', bpb.data.message);

    const sessionId = bpb.data.data.id;

    const result = await stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      activeElement = <h1>{result.error.message}</h1>;
    }
  };

  const payPalHandler = async (details, data) => {
    const bpb = await axios.post('/paypal', {
      paypalRef: details.id,
      orderId: order._id,
      price: order.price,
    });

    if (bpb.data.status === 'error') {
      window.location.replace('/payment?success=false');
    } else {
      //dispatch thanks you page
      window.location.replace('/payment?success=true');
    }
  };

  if (!token || token === '') {
    activeElement = <FetchError error="You dont have permission to access this page" />;
  } else {
    if (!auth.token) {
      activeElement = (
        <div>
          <Modal />
        </div>
      );
      dispatch(actions.modalOpen({ modalType: 'signin', modalData: { forceOpen: true } }));
    }

    if (auth.token && !userValid) {
      axios
        .get(`/reservations/payment?t=${token}`)
        .then((response) => {
          setUserValid(true);
          setOrder(response.data.data);
        })
        .catch((err) => err.response.status === 401 && dispatch(actions.authLogout()));
    }

    if (auth.token && userValid && !_.isEmpty(order))
      activeElement = (
        <App>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="w-full mt-4 lg:w-1/2">
              <DetailsTable
                details={_.pick(order, [
                  'orderType',
                  'number',
                  'badge',
                  'tonnes',
                  'currency',
                  'price',
                ])}
                headers={['Order Details', 'Totals']}
              />
            </div>
            <div
              className="w-full mt-4 lg:w-1/2 bg-no-repeat bg-center"
              style={{ backgroundImage: `url(${order.image})` }}
            >
              {/* <img src={order.image} className="h-full" /> */}
            </div>
          </div>
          {/* <div className="my-10">
            <h4 className="mb-2 text-lg text-gray-700">Invoice Details</h4>
            <div className="flex">
              <input type="checkbox" onClick={() => invoiceHandler(false)} /><span className="ml-2 text-gray-700">Don't invoice me</span>
              <input type="checkbox" onClick={() => invoiceHandler(true)} /><span className="ml-2 text-gray-700">Use my account details</span>
              <input className="ml-4" type="checkbox" onClick={() => invoiceHandler('custom')} /><span className="ml-2 text-gray-700">Use custom details</span></div>
          </div> */}
          <h4 className="mt-12 mb-4 text-lg text-center text-gray-700">Pay With</h4>
          <div className="flex align-center justify-center">
            {/* <button onClick={stripeHandler} className="btn bg-indigo-500 hover:bg-indigo-700 py-0 mr-4 shadow-none" style={{ marginBottom: '6px' }}>
              <svg className="h-10" version="1.1" x="0" y="0" viewBox="0 0 468 222.5" fill="currentColor" stroke="none" strokeWidth="1.5"><path d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zM301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zM223.8 61.7l25.1-5.4V36l-25.1 5.3zM223.8 69.3h25.1v87.5h-25.1zM196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zM146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z" /></svg>
            </button> */}
            <button
              onClick={stripeHandler}
              className="px-12 btn bg-indigo-500 hover:bg-indigo-700 py-0 mr-4 shadow-none"
              style={{ marginBottom: '6px' }}
            >
              <svg
                className="h-10 w-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
              </svg>
            </button>
            {order.currency !== 'COP' ? (
              <PayPalButton
                amount={order.price}
                // currency={order.currency}
                shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                  return payPalHandler(details, data);
                }}
                options={{
                  clientId: payPalId,
                  currency: order.currency,
                }}
                style={{
                  layout: 'horizontal',
                  color: 'blue',
                  shape: 'rect',
                  label: 'paypal',
                  tagline: false,
                  height: 55,
                  width: 192,
                }}
              />
            ) : null}
          </div>
          <p className="ml-auto text-gray-700 text-lg mt-6">
            For wire payments please find payment details in your invoice, or contact{' '}
            <a href="mailto:business@standfortrees.org" rel="noopener noreferrer" target="_blank">
              business@standfortrees.org
            </a>{' '}
            for support.
          </p>
        </App>
      );
  }
  return <Fragment>{activeElement}</Fragment>;
};
export default Checkout;
