import React, { Fragment, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { getDataApi } from '../../../utils/api'
import Table from '../../Table/Table'
import csv from '../../../utils/csv'

const Discounts = (props) => {
  const [dataSet, setDataSet] = useState({})
  const headers = [
    'code',
    'status',
    'size',
    'value',
    'discount',
    'startsAt',
    'expiresAt',
    'codeGeneration'
  ]

  const getSetData = async (query) => {
    let discounts = await getDataApi(`${props.match.url}`, query)
    const projects = await getDataApi(`admin/projects`)

    discounts = discounts.map(e => {
      if (e.projectId)
        e.projectName = projects.find(project => project._id === e.projectId).name

      e.assignedToOrder = e.orderId ? 'Yes' : 'No'

      return e
    })

    setDataSet(discounts)
  }

  useEffect(() => {
    if (isEmpty(dataSet))
      getSetData()
    // eslint-disable-next-line
  }, [])


  return (
    <div>
      <h1 className="mb-8">Discounts</h1>
      <Fragment>
        {!isEmpty(dataSet) &&

          <Table
            dataSet={dataSet}
            headers={headers}
            getSet={getSetData}
            setData={setDataSet}
            history={props.history}
            route={props.match.url}
          // dateRangeCategories={['createdDate', 'issuedDate']}
          />
        }
        <div className="flex space-x-4 items-center mt-4">
          {!isEmpty(dataSet) &&
            <button className="btn btn-teal" onClick={() => csv(dataSet, headers, 'discounts')}>Download CSV</button>
          }
          <button className="btn btn-danger" onClick={() => props.history.push(`add/discounts`)}>Add Discount</button>
        </div>
      </Fragment>
    </div>
  )

}
export default Discounts

