import React, { useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { deleteDataApi, getDataApi } from '../../../utils/api';
import ExtraInfo from './ExtraInfo';
import ExtraBundles from './ExtraBundles';

const Extra = (props) => {
  const [dataSet, setDataSet] = useState({});

  const getSetData = async (query) => {
    const extra = await getDataApi(`${props.match.url}`, query);
    if (extra.orderId) extra.order = await getDataApi(`/admin/orders/${extra.orderId}`);
    setDataSet({ extra });
  };

  const updateDataSet = (field, data) => {
    const updData = { ...dataSet };
    updData[field] = { ...dataSet[field], ...data };
    setDataSet(updData);
  };

  const deleteExtraHandler = async () => {
    if (dataSet) await deleteDataApi(`/admin/extras/${dataSet.extra._id}`);
    window.location.replace('/admin/extras');
  };

  if (isEmpty(dataSet)) getSetData();

  return (
    <div>
      <h3 className="text-gray-700 text-center text-3xl my-2">Extra Details</h3>
      {!isEmpty(dataSet) && (
        <Fragment>
          <ExtraInfo dataSet={dataSet} updateDataSet={updateDataSet} />
          <ExtraBundles dataSet={dataSet} updateDataSet={updateDataSet} />
          <button className="btn btn-danger mt-4 mx-auto" onClick={deleteExtraHandler}>
            Delete Extra
          </button>
        </Fragment>
      )}
    </div>
  );
};
export default Extra;
