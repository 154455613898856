import React from 'react'
import { useForm } from 'react-hook-form'

const Form = ({ initData, submitHandler }) => {
  const { register, handleSubmit, errors } = useForm({ defaultValues: initData })
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="cols xl:space-x-4">
        <div className="xl:w-1/2 w-full text-center">
          <input className="mt-4 mx-auto w-full" type="text" name="name" ref={register({ required: true })} placeholder="Name" />
          {errors.name && <p className="error">Please enter name</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="email" ref={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })} placeholder="Email Address" />
          {errors.email && <p className="error">Please enter a valid email address</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="company" ref={register({ required: true })} placeholder="Company Name" />
          {errors.company && <p className="error">Please enter company name</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="country" ref={register({ required: true })} placeholder="Country" />
          {errors.country && <p className="error">Please enter country</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="state" ref={register()} placeholder="State (Optional)" />
          {errors.state && <p className="error">Please enter state</p>}
        </div>
        <div className="xl:w-1/2 w-full text-center">
          <input className="mt-4 mx-auto w-full" type="text" name="position" ref={register()} placeholder="Position (Optional)" />
          {errors.position && <p className="error">Please enter position</p>}


          <input className="mt-4 mx-auto w-full" type="text" name="city" ref={register({ required: true })} placeholder="City" />
          {errors.city && <p className="error">Please enter city</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="address1" ref={register({ required: true })} placeholder="Address Line 1" />
          {errors.address1 && <p className="error">Please enter address</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="address2" ref={register()} placeholder="Address Line 2 (Optional)" />
          {errors.address2 && <p className="error">Please enter address</p>}

          <input className="mt-4 mx-auto w-full" type="text" name="zip" ref={register({ required: true })} placeholder="Zip Code" />
          {errors.zip && <p className="error">Please enter zip code</p>}

        </div>
      </div>
      <button className="btn block mt-8 mx-auto" type="submit">Submit</button>
    </form>)
}

export default Form