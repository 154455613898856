import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from '../../../api/axios'
import BtnSpinner from '../../../layout/BtnSpinner/BtnSpinner'

const ProjectInfo = (
  { dataSet,
    updateDataSet,
  }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updated, setUpdated] = useState(false)

  const { register, handleSubmit, errors } = useForm({ defaultValues: dataSet.project })

  const submitHandler = async (data) => {
    setLoading(true)

    axios.patch(`/admin/projects/${dataSet.project._id}`, data)
      .then((res) => {
        setUpdated(true)
        setError(false)
        updateDataSet('project', { ...res.data.data })
      })
      .catch(() => {
        setUpdated(false)
        setError(true)
      })

    setLoading(false)
  }

  return (
    <div className="flex flex-wrap lg:flex-no-wrap mx-auto shadow-md rounded py-4 px-8 lg:space-x-8 mb-6">
      <form className="w-full" onSubmit={handleSubmit(submitHandler)}>

        <h5 className="text-teal-500">Project Code</h5>
        <h5 className="text-gray-600 mb-4">{dataSet.project.prj_code}</h5>

        <h5 className="text-teal-500">Name</h5>
        <input type="text" className="my-2 w-full" name="name" ref={register({ required: true })} />
        {errors.name && <p className="error">Please enter name</p>}

        <h5 className="text-teal-500">Details</h5>
        <input type="text" className="my-2 w-full" name="details" ref={register({ required: true })} />
        {errors.details && <p className="error">Please enter details</p>}

        <h5 className="text-teal-500">Country</h5>
        <input type="text" className="my-2 w-full" name="country" ref={register({ required: true })} />
        {errors.country && <p className="error">Please enter country</p>}

        <h5 className="text-teal-500">Status</h5>
        <div className="relative w-full styled-select my-2">

          <select className="focus:outline-none focus:bg-white focus:border-gray-500" name="status" ref={register()}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
          </div>
        </div>

        <h5 className="text-teal-500">Video Available</h5>
        <div className="relative w-full styled-select my-2">

          <select className="focus:outline-none focus:bg-white focus:border-gray-500" name="video" ref={register()}>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
          </div>
        </div>

        <button className="btn block mt-8 mx-auto" type="submit">{loading ? <BtnSpinner /> : 'Save Details'}</button>
        {updated && <p className="admin-info">Project updated successfully</p>}
        {error && <p className="admin-error">Error updating project</p>}
      </form>

    </div>

  )
}

export default ProjectInfo