import axios from '../api/axios';

export const apiUtils = (sortBy, filterBy, filterValue, order = true) => {
  let query = {};
  let sort = order ? '' : '-';

  if (filterBy) query[filterBy] = filterValue;

  if (sortBy) query.sort = `${sort}${sortBy}`;

  return query;
};

export const getDataApi = async (endpoint, query) => {
  try {
    const response = await axios.get(endpoint, { params: query });
    const data = response.data && response.data.data ? response.data.data : true;
    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const createDataApi = async (endpoint, data) => {
  // await timeout(2000)
  // return false
  try {
    const response = await axios.post(endpoint, data);
    return response.data.data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const updateDataApi = async (endpoint, data) => {
  // await timeout(2000)
  // return false
  try {
    const response = await axios.patch(endpoint, data);
    return response.data.data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const deleteDataApi = async (endpoint, data) => {
  try {
    const response = await axios.delete(endpoint, data);
    return response.status;
  } catch (e) {
    console.error(e);
    return false;
  }
};

// eslint-disable-next-line
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
