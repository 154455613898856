import * as actypes from '../actypes'
import axios from '../../api/axios'
import { setValid } from './pages'



export const donorSet = (payload) => {
  return {
    type: actypes.DONOR_SET,
    payload
  }
}

export const donorInit = (level) => {
  return (dispatch, getState) => {
    const app = getState().pages.app
    axios.get(`/badges?app=${app}&level=${level}`)
      .then(res => {
        dispatch(setValid(true))
        dispatch(_donorInit(res.data.data[0]))
      })
      .catch(err => {
        console.error(err)
      })
  }
}


export const _donorInit = (badge) => {
  return {
    type: actypes.DONOR_SET,
    payload: { badge }
  }
}