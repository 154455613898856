import React from 'react';
import { connect } from 'react-redux';
import logoImg from '../../assets/img/logo-white.png';
import logoTrees from '../../assets/img/logo-trees.png';
import Dropdown from './Dropdown';

const Header = (props) => {
  const appName = props.app === 'dp' ? 'Supporter Program' : 'Calculate & Offset Program';

  return (
    <div className="bg-teal-500 text-white flex flex-initial justify-between items-center font-display font-semibold h-16 cursor-pointer relative h-20">
      <img
        className="w-48 hidden md:block"
        src={logoImg}
        alt="sft-logo"
        onClick={() => {
          window.location.replace('/');
        }}
      />
      <img
        className="h-10 md:hidden"
        src={logoTrees}
        alt="logo-mobile"
        onClick={() => {
          window.location.replace('/');
        }}
      />
      <h2 className="text-center text-sm lg:text-xl px-4">{appName}</h2>
      <Dropdown activeUser={props.auth.user} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.pages.app,
});

export default connect(mapStateToProps)(Header);
