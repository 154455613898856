import * as actypes from '../actypes'
import { updateObject } from '../helpers'

const initState = {
  projects: {},
  selected: [],
  available: [],
  split: 1,
  extras: false
}

// const initState = {
//   "projects": [
//     {
//       "_id": "5f4962e58ecfdb601448cd55",
//       "image": "https://via.placeholder.com/300",
//       "prj_code": "104000000014152",
//       "name": "Southern Cardamom",
//       "country": "Cambodia",
//       "details": "<p>Protects one of Asia’s largest tropical rainforests. Elephants, clouded leopards, and indigenous communities</p>",
//       "__v": 0,
//       "tonnes": 520
//     },
//     {
//       "_id": "5f4962e58ecfdb601448cd56",
//       "image": "https://via.placeholder.com/300",
//       "prj_code": "103000000000129",
//       "name": "Congo Basin Rainforest",
//       "country": "D.R. Congo",
//       "details": "<p>Protects the most threatened frontier of the Congo Basin Forest</p><p>Conserves critical habitat for bonobos, mankind's closest living relative</p>",
//       "__v": 0,
//       "tonnes": 125
//     },
//     {
//       "_id": "5f4962e58ecfdb601448cd57",
//       "image": "https://via.placeholder.com/300",
//       "prj_code": "104000000012298",
//       "name": "Guatemala Conservation Coast",
//       "country": "Guatemala",
//       "details": "<p>Empowerment of women and girls through health care and education</p><p>Protecting habitat for 426 birds in a bird 'super highway'</p>",
//       "__v": 0,
//       "tonnes": 2952,
//       "extras": [
//         {
//           "image": "https://via.placeholder.com/300",
//           "_id": "5f4962e58ecfdb601448cd58",
//           "name": "Extra Guatemala 1",
//           "type": "activity",
//           "details": "<p>Lorem Ipsum dolor sit amet, consectetur adipiscing elit</p>"
//         }
//       ]
//     },
//     {
//       "_id": "5f4962e58ecfdb601448cd53",
//       "image": "https://via.placeholder.com/300",
//       "prj_code": "100000000001652",
//       "name": "Brazilian Rosewood",
//       "country": "Brazil",
//       "details": "<p>Protects endangered tree species like the Brazilian Rosewood. Supports secure land tenure rights for forest communities</p>",
//       "extras": [
//         {
//           "image": "https://via.placeholder.com/300",
//           "_id": "5f4962e58ecfdb601448cd54",
//           "name": "Extra Brazilian 1",
//           "type": "activity",
//           "details": "<p>Lorem Ipsum dolor sit amet, consectetur adipiscing elit</p>"
//         },
//         {
//           "image": "https://via.placeholder.com/300",
//           "_id": "5f4962e58ecfdb601448cd56",
//           "name": "Extra Brazilian 2",
//           "type": "activity",
//           "details": "<p>Lorem Ipsum dolor sit amet, consectetur adipiscing elit</p>"
//         },
//         {
//           "image": "https://via.placeholder.com/300",
//           "_id": "5f4962e58ecfdb601448cd55",
//           "name": "Extra Brazilian 3",
//           "type": "activity",
//           "details": "<p>Lorem Ipsum dolor sit amet, consectetur adipiscing elit</p>"
//         },
//         {
//           "image": "https://via.placeholder.com/300",
//           "_id": "5f4962e58ecfdb601448cd58",
//           "name": "Extra Brazilian Video",
//           "type": "video",
//           "details": "<p>Lorem Ipsum dolor sit amet, consectetur adipiscing elit</p>"
//         }
//       ],
//       "__v": 0,
//       "tonnes": 2952
//     }
//   ],
//   "selected": [
//     "5f4962e58ecfdb601448cd53",
//     "5f4962e58ecfdb601448cd57"
//   ],
//   "available": [
//     "5f4962e58ecfdb601448cd53"
//   ],
//   "split": 1,
//   "extras": true
// }

const projectsSet = (state, action) => {
  const updState = { ...state }
  updState.projects = action.payload
  return updateObject(state, updState)
}

const projectsSelected = (state, action) => {
  const updState = { ...state }
  updState.selected = action.payload
  return updateObject(state, updState)
}

const projectsOptions = (state, action) => {
  return updateObject(state, action.payload)
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actypes.PROJECTS_RESET: return initState
    case actypes.PROJECTS_SET: return projectsSet(state, action)
    case actypes.PROJECTS_SELECTED: return projectsSelected(state, action)
    case actypes.PROJECTS_OPTIONS: return projectsOptions(state, action)
    default: return state
  }
}

export default reducer