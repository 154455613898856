export const RESET_STORE = 'RESET_STORE'

export const SAVESECTION = 'SAVESECTION'
export const DELETESECTION = 'DELETESECTION'

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'

export const PAGES_RESET = 'PAGES_RESET'
export const PAGES_INIT = 'PAGES_INIT'
export const PAGES_PREV = 'PAGES_PREV'
export const PAGES_NEXT = 'PAGES_NEXT'
export const PAGES_JUMP = 'PAGES_JUMP'
export const PAGES_VALID = 'PAGES_VALID'

export const FORMS_RESET = 'FORMS_RESET'
export const FORMS_INIT = 'FORMS_INIT'
export const FORMS_DELETE = 'FORMS_DELETE'
export const FORMS_SET_OPTION = 'FORMS_SET_OPTION'

export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const RESULTS_SET = 'RESULTS_SET'
export const RESULTS_RESET = 'RESULTS_RESET'

export const BADGES_SET = 'BADGES_SET'
export const BADGES_RESET = 'BADGES_RESET'
export const BADGES_UPDATE = 'BADGES_UPDATE'
export const BADGES_SELECTED = 'BADGES_SELECTED'

export const PROJECTS_SET = 'PROJECTS_SET'
export const PROJECTS_RESET = 'PROJECTS_RESET'
export const PROJECTS_SELECTED = 'PROJECTS_SELECTED'
export const PROJECTS_OPTIONS = 'PROJECTS_OPTIONS'

export const DONOR_INIT = 'DONOR_INIT'
export const DONOR_SET = 'DONOR_SET'

export const SET_OPTIONS = 'SET_OPTIONS'

export const EXTRAS_SET = 'EXTRAS_SET'
export const EXTRAS_RESET = 'EXTRAS_RESET'

export const THANKS_SET = 'THANKS_SET'
export const THANKS_RESET = 'THANKS_RESET'