import React, { useState, Fragment } from 'react';
import Form from './Form';
import { getDataApi, updateDataApi } from '../../../../utils/api';
import downloadPdfHandler from '../../../../utils/pdf';
const Reservation = ({ data, updateDataSet }) => {
  const [updated, setUpdated] = useState('');
  const [error, setError] = useState('');

  const getDataApiHandler = async (endpoint, id, option) => {
    const res = await getDataApi(endpoint + id);

    if (res) {
      setError('');
      setUpdated(option);
    } else {
      setUpdated('');
      setError(option);
    }
  };

  const updateDataApiHandler = (payload, option) => {
    const updData = { ...data.reservation };
    updData[option] = payload;
    // setLoading(option)
    updateDataApi(`/admin/reservations/${data.reservation._id}`, updData)
      .then(() => {
        setError('');
        setUpdated(option);
        updateDataSet('reservation', updData);
      })
      .catch(() => {
        setUpdated('');
        setError(option);
      });
  };
  if (data.reservation)
    return (
      <Fragment>
        <h3 className="text-gray-700 text-center text-3xl my-6">Reservation</h3>
        <div className="flex flex-wrap lg:flex-no-wrap mx-auto card-lg lg:space-x-8 mb-6 admin-details">
          {data.reservation.contractDetails && (
            <div className="w-full lg:w-1/2 p-4">
              <h3 className="mb-4 text-gray-700">Contract Details</h3>
              <Form
                initData={data.reservation.contractDetails}
                submitHandler={(data) => updateDataApiHandler(data, 'contractDetails')}
              />
              {updated === 'contractDetails' && (
                <p className="admin-info">Contract details successfully updated</p>
              )}
              {error === 'contractDetails' && (
                <p className="admin-error">Error updating the contract details</p>
              )}
            </div>
          )}
          {data.reservation.invoiceDetails && (
            <div className="w-full lg:w-1/2 p-4 lg:border-l">
              <h3 className="mb-4 text-gray-700">Invoice Details</h3>
              <Form
                initData={data.reservation.invoiceDetails}
                submitHandler={(data) => updateDataApiHandler(data, 'invoiceDetails')}
              />
              {updated === 'invoiceDetails' && (
                <p className="admin-info">Invoice details successfully updated</p>
              )}
              {error === 'invoiceDetails' && (
                <p className="admin-error">Error updating the invoice details</p>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-wrap lg:flex-no-wrap mx-auto card-lg lg:space-x-8 mb-6 admin-details">
          <div className="w-full lg:w-1/2 p-4">
            {data.reservation.invoiceDetails && (
              <Fragment>
                <h3 className="mb-4 text-gray-700 ">PO Number</h3>
                <input
                  className="my-4 mx-auto w-full"
                  type="text"
                  value={data.reservation.PONumber}
                  onChange={(e) => updateDataSet('reservation', { PONumber: e.target.value })}
                />
                <button
                  className="btn mb-4"
                  onClick={() => updateDataApiHandler(data.reservation.PONumber, 'PONumber')}
                >
                  Change PO Number
                </button>
                {updated === 'PONumber' && (
                  <p className="admin-info">Invoice details successfully updated</p>
                )}
                {error === 'PONumber' && (
                  <p className="admin-error">Error updating the invoice details</p>
                )}
              </Fragment>
            )}
          </div>
          {data.reservation.contractDetails || data.reservation.invoiceDetails ? (
            <div className="w-full lg:w-1/2 p-4">
              <h3 className="mb-4 text-gray-700 mb-4">Options</h3>
              {data.reservation.contractDetails && (
                <Fragment>
                  <button
                    className="btn btn-danger mb-6"
                    onClick={() =>
                      getDataApiHandler('/admin/orders/contract/', data.order._id, 'contract')
                    }
                  >
                    Generate Eversign Contract
                  </button>
                  {updated === 'contract' && (
                    <p className="admin-info">Contract generated &amp; sent</p>
                  )}
                  {error === 'contract' && <p className="admin-error">Error generating contract</p>}
                </Fragment>
              )}
              {data.reservation.invoiceDetails && (
                <Fragment>
                  <button
                    className="btn btn-teal mb-6"
                    onClick={() =>
                      downloadPdfHandler(
                        '/admin/reservations/invoice',
                        data.reservation._id,
                        'invoice',
                      )
                    }
                  >
                    Download Invoice
                  </button>
                  {updated === 'invoice' && (
                    <p className="admin-info">Contract generated &amp; sent</p>
                  )}
                  {error === 'invoice' && <p className="admin-error">Error generating contract</p>}
                </Fragment>
              )}
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  else return null;
};

export default Reservation;
